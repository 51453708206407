import { notifications } from "@mantine/notifications";

function validateEmail(email: string) {
  let emailTrim = email.trim();
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(emailTrim).toLowerCase());
}

const getFinancialValueFromNumeric = (value: number | string | undefined) => {
  if (value !== undefined) {
    return parseFloat(value.toString()).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return "...";
};

const getFullDate = (dateString: string | undefined | number) => {
  if (dateString) {
    const d = new Date(dateString);
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const year = d.getFullYear();
    const month = MONTHS[d.getMonth()];
    const day = DAYS[d.getDay()];
    return `${day} ${d.getDate()} ${month}, ${year} `;
  }
  return dateString;
};
const validatePhone = (phone: string) => {
  return phone.length === 10;
};
const getHalfDate = (dateString: string | undefined) => {
  if (dateString) {
    const d = new Date(dateString);
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const year = d.getFullYear();
    const month = MONTHS[d.getMonth()];

    return `${month}-${d.getDate()}-${year} `;
  }
  return dateString;
};
function generateRandomString(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
const getBaseEmailDomain = () => {
  return "@getnafiam.org";
};
const DefaultErrorNotification = (message?: string, title?: string) => {
  notifications.show({
    color: "red",
    message: message ?? "An error occurred",
    title: title ?? "Error!",
  });
};
const DefaultSuccessNotification = (message?: string, title?: string) => {
  notifications.show({
    color: "green",
    message: message ?? "Completed!",
    title: title ?? "Success!",
  });
};

const getEllipsisWithString = (string: string, length: number) => {
  if (string.length < length - 5) {
    return string;
  } else {
    return `${string.substring(0, length)}...`;
  }
};
const validateServiceNumber = (num: string) => {
  const regex = /^NAF\d{2}\/\d{5}F?$/;
  return regex.test(num);
};
const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
const DownloadFile = async (url: string, fileName: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName || "downloaded-file";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      DefaultErrorNotification("Could not download file");
    });
};
const checkIsValidNumber = (number: any) => {
  if (!number) {
    return false;
  } else {
    const wrong = isNaN(parseInt(number)) || parseInt(number) === 0;
    return !wrong;
  }
};
export {
  validateEmail,
  validateServiceNumber,
  getFinancialValueFromNumeric,
  getFullDate,
  getHalfDate,
  generateRandomString,
  getBaseEmailDomain,
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getEllipsisWithString,
  shuffleArray,
  DownloadFile,
  checkIsValidNumber,
  validatePhone,
};
