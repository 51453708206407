import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Text,
  Button,
  Paper,
  TextInput,
  PasswordInput,
  Select,
  Textarea,
  NumberInput,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import Cookies from "js-cookie";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validateEmail,
  validatePhone,
} from "../../Lib/Methods";

import { PerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { LoginResponse, NonPaginatedResponse } from "../../Lib/Responses";
import States from "../../Lib/States.json";

import "./styles.scss";

interface RegisterFormProps {
  email: string;
  password: string;
  confirmPassword: string;
  phone: string;
  firstname: string;
  lastname: string;
  state: string;
  address: string;
}

const Register: React.FC = () => {
  const navigate = useNavigate();
  const registerForm = useForm<RegisterFormProps>({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      firstname: "",
      lastname: "",
      state: "",
      address: "",
    },
    validate: {
      email: (value) =>
        validateEmail(value.trim()) ? null : "Please enter a valid email!",

      password: (value) =>
        value.length >= 6 ? null : "At least SIX characters",
      confirmPassword: (value, values) =>
        value === values.password ? null : "Passwords do not match",
      phone: (value) =>
        validatePhone(value.toString()) ? null : "Enter a valid phone number!",
      firstname: (value) => (value.length < 3 ? "Enter your firstname!" : null),
      lastname: (value) => (value.length < 3 ? "Enter your surname!" : null),
      state: (value) =>
        !value || value.length === 0 ? "Select a state!" : null,
      address: (value) =>
        value.length < 10 ? "Enter your full address!" : null,
    },
  });
  const states = States.map((state) => {
    return {
      value: state.alias,
      label: state.state,
    };
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const RegisterClient = async () => {
    const { hasErrors } = registerForm.validate();
    if (!hasErrors) {
      setLoading(true);
      const { email, password, phone, firstname, lastname, state, address } =
        registerForm.values;
      const r = await PerformRequest<NonPaginatedResponse<LoginResponse>>({
        method: "POST",
        route: Endpoints.Register,
        data: {
          email: email.toLowerCase(),
          password,
          phone: phone.toString(),
          firstname,
          lastname,
          location: {
            address,
            state,
          },
        },
      }).catch(() => {
        setLoading(false);
      });
      setLoading(false);
      if (r) {
        const { status, message, data } = r;
        if (status === 201) {
          DefaultSuccessNotification("Logged in successfully!", "Success");
          Cookies.set("token", data?.data?.token ?? "");
          navigate("/");
        } else {
          DefaultErrorNotification(message);
        }
      }
    }
  };
  return (
    <div className="register-container">
      <Paper className="register" shadow="sm">
        <Text fw={500} fz="xl">
          Create An Account
        </Text>
        <TextInput
          name="email"
          placeholder="Enter email address"
          w="100%"
          label="Email"
          {...registerForm.getInputProps("email")}
        />
        <div className="flex-row width-100 align-center justify-between">
          <TextInput
            name="name"
            placeholder="Enter first name"
            w="48%"
            label="First Name"
            {...registerForm.getInputProps("firstname")}
          />
          <TextInput
            name="name"
            placeholder="Enter last name"
            w="48%"
            label="Last Name"
            {...registerForm.getInputProps("lastname")}
          />
        </div>
        <NumberInput
          prefix="+234 "
          maxLength={16}
          hideControls
          step={0}
          type="tel"
          {...registerForm.getInputProps("phone")}
          placeholder="Enter phone number"
          label="Phone"
          w="100%"
        />
        <div className="flex-row width-100 align-center justify-between">
          <PasswordInput
            placeholder="Enter password"
            w="48%"
            label="Password"
            {...registerForm.getInputProps("password")}
          />
          <PasswordInput
            placeholder="Confirm password"
            w="48%"
            label="Confirm Password"
            {...registerForm.getInputProps("confirmPassword")}
          />
        </div>
        <Select
          data={states}
          {...registerForm.getInputProps("state")}
          label="State"
          w="100%"
          searchable
          placeholder="Select state of residence"
        />
        <Textarea
          label="Address"
          w="100%"
          description="Your address will be used to deliver products"
          placeholder="Enter your full address"
          {...registerForm.getInputProps("address")}
        />
        <Button
          fullWidth
          loading={isLoading}
          onClick={() => {
            RegisterClient();
          }}
        >
          Continue
        </Button>
        <div className="flex-row align-center width-100 justify-center">
          <Text>Already have an account?</Text>
          &nbsp;&nbsp;
          <Link to="/login">
            <Text c="blue">Login</Text>
          </Link>
        </div>
      </Paper>
    </div>
  );
};
export default Register;
