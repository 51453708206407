import { Text, Loader } from "@mantine/core";
import "./styles.scss";

interface LoaderProps {
  size?: number;
}
export default function MiniLoader({ size }: LoaderProps) {
  return (
    <div className="flex-row width-100 align-center justify-center miniloader-container">
      <div className="miniloader flex-row align-center justify-center">
        <Text fw={500} fz={20}>
          Loading
        </Text>
        <Loader radius={2} stroke="1" ml={20} size={size ?? 30} />
      </div>
    </div>
  );
}
