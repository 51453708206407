import { useContext, useEffect, useState } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import {
  Text,
  Alert,
  Button,
  ActionIcon,
  Loader,
  Badge,
  CopyButton,
  Table,
  Select,
  Group,
  Skeleton,
  Card,
  Divider,
} from "@mantine/core";
import {
  IconRefresh,
  IconExclamationCircle,
  IconTruck,
  IconLoader3,
  IconNavigationCheck,
  IconCircleFilled,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import Cookies from "js-cookie";

import { AppContext } from "../../Context/AppContext";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { NonPaginatedResponse } from "../../Lib/Responses";
import {
  CartProductProps,
  OrderProductProps,
  OrderProps,
  ProductProps,
  SingleOrderWithClient,
} from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";

import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getEllipsisWithString,
  getFinancialValueFromNumeric,
} from "../../Lib/Methods";

import "./styles.scss";
import MiniLoader from "../../Components/MiniLoader";

const SingleOrder: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { orderID } = params;
  const {
    data,
    reloadData,
    status,
    isLoading: isFetchingOrder,
  } = usePerformRequest<NonPaginatedResponse<OrderProps>>({
    method: "GET",
    url: `${Endpoints.GetSingleOrder}/${orderID}`,
  });
  if (status === 401) {
    Cookies.remove("token");
    navigate("/login");
  }

  return (
    <Container maxWidth="md">
      <div className="single-order-container">
        <div className="flex-row align-center justify-between width-100">
          <Text fw={600} fz="xl">
            Order Details
          </Text>
        </div>
        <br />
        {isFetchingOrder ? (
          <Group align="center" mt={30} justify="center">
            <MiniLoader />
          </Group>
        ) : (
          <>
            {data?.data ? (
              <div className="flex-col width-100 body">
                <div className="item-row">
                  <Badge radius="xs">Created On</Badge>
                  <Text className="value" fz={16} fw={500}>
                    {dayjs(data.data.dateCreated).format("dddd DD MMMM YYYY")}
                  </Text>
                </div>

                <div className="item-row">
                  <Badge radius="xs">Amount</Badge>
                  <Text className="value" fw={600} fz="xl">
                    ₦{getFinancialValueFromNumeric(data.data.amount)}
                  </Text>
                </div>
                <div className="item-row">
                  <Badge radius="xs">Transaction Reference</Badge>
                  <CopyButton value={data.data.reference}>
                    {({ copied, copy }) => (
                      <Text
                        className="value pointer"
                        w="fit-content"
                        fz="lg"
                        color={copied ? "blue" : "black"}
                        onClick={() => {
                          copy();
                          DefaultSuccessNotification(
                            "Reference copied to clipboard"
                          );
                        }}
                      >
                        {data.data.reference}
                      </Text>
                    )}
                  </CopyButton>
                </div>
                <div className="item-row">
                  <Badge radius="xs">Transaction Status</Badge>
                  <Badge
                    className="value"
                    size="xl"
                    fz="sm"
                    mt={10}
                    color={data.data.status === "success" ? "green" : "red"}
                  >
                    {data.data.status}
                  </Badge>
                </div>
                <Badge radius="xs" mb={10}>
                  Items in Order
                </Badge>

                <div className="items flex-col">
                  {data.data.products.map((product) => {
                    return <CartProductCard product={product} />;
                  })}
                </div>
                <Divider w="100%" />
                <Text fw={600} fz="xl">
                  Delivery Details
                </Text>
                <div className="item-row">
                  <Badge radius="xs" mt={20}>
                    Delivery Status
                  </Badge>
                  <Text
                    className="value"
                    tt="capitalize"
                    c={
                      data.data.delivered === "delivered"
                        ? "green"
                        : data.data.delivered === "pending"
                        ? "orange"
                        : "blue"
                    }
                    size="xl"
                    fz="lg"
                    mt={10}
                  >
                    {data.data.delivered}
                  </Text>
                </div>
                <div className="item-row">
                  <Badge radius="xs" mt={20}>
                    Address
                  </Badge>
                  <Text
                    className="value"
                    tt="capitalize"
                    size="xl"
                    fz="lg"
                    mt={10}
                  >
                    {data.data.location.address}, {data.data.location.state}{" "}
                    -&nbsp;
                    <b>{data.data.location.country}</b>
                  </Text>
                </div>
                {data.data.deliveryDate && (
                  <div className="item-row">
                    <Badge radius="xs" mt={20}>
                      Delivery Date
                    </Badge>
                    <Text className="value" size="xl" fz="sm" mt={10}>
                      {dayjs(data.data.deliveryDate).format(
                        "dddd, DD MMMM YYYY"
                      )}{" "}
                      at{" "}
                      <b>{dayjs(data.data.deliveryDate).format("HH:MM A")}</b>
                    </Text>
                  </div>
                )}
              </div>
            ) : (
              <Container maxWidth="sm">
                <Alert title="Not Found" mt={30}>
                  <IconExclamationCircle color="var(--mantine-color-blue-6)" />
                  <Text>Order not found on platform</Text>
                </Alert>
              </Container>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

function CartProductCard({ product }: OrderProductProps) {
  const context = useContext(AppContext);
  const {
    data: productDetails,
    isLoading: isLoadingProduct,
    status,
  } = usePerformRequest<NonPaginatedResponse<ProductProps>>({
    method: "GET",
    url: `${Endpoints.GetSingleProduct}/${product.id}`,
  });
  const featuredImage = productDetails?.data.images.find(
    (image) => image.cloudinaryID === productDetails.data.featuredImage
  );
  const priceStat = productDetails?.data.prices.find(
    (p) => p.size === product.size && p.stock >= 1
  );
  const productStat = priceStat
    ? {
        size: priceStat.size,
        price: priceStat.price,
      }
    : undefined;

  const getMaxCharacters = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 900) {
      return 45;
    } else if (screenWidth > 560 && screenWidth < 900) {
      return 75;
    } else {
      return 30;
    }
  };
  return (
    <Card
      shadow="sm"
      className="cart-item flex-row justify-between align-center"
      key={product._id}
    >
      {isLoadingProduct ? (
        <Skeleton h="100%" w="100%" />
      ) : (
        <>
          {status === 404 ? (
            <div className="flex-row width-100 align-center">
              <IconExclamationCircle size={40} color="red" />
              <Text fw={500} fz="lg" ml={10}>
                Product not found!
              </Text>
            </div>
          ) : (
            <>
              <img src={featuredImage?.url} className="image" alt="" />
              <div className="details flex-col justify-between">
                <Link to={`/product/${product.id}`}>
                  <Text c="black" fw={500} className="name">
                    {getEllipsisWithString(
                      productDetails?.data.name ?? "",

                      getMaxCharacters()
                    )}
                  </Text>
                </Link>
                <Text fw={600} className="amount" c="blue">
                  ₦{getFinancialValueFromNumeric(productStat?.price)}
                </Text>
                <div className="flex-row align-center">
                  <Text c="dimmed" fz="sm">
                    Colour:
                  </Text>
                  <Text fz="sm" ml={5}>
                    {product.colour}
                  </Text>
                </div>
                <div className="flex-row align-center">
                  <Text c="dimmed" fz="sm">
                    Size:
                  </Text>
                  <Text fz="sm" ml={5}>
                    {product.size}
                  </Text>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Card>
  );
}
export default SingleOrder;
