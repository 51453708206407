import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";

import {
  Alert,
  Button,
  Divider,
  Pagination,
  Paper,
  Badge,
  Text,
  TextInput,
} from "@mantine/core";
import {
  IconArrowLeft,
  IconExclamationCircle,
  IconHeart,
  IconShoppingCart,
} from "@tabler/icons-react";

import { AppContext } from "../../Context/AppContext";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { NonPaginatedResponse, PaginatedResponse } from "../../Lib/Responses";
import { OrderProps, PaginationProps, ProductProps } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getEllipsisWithString,
  getFinancialValueFromNumeric,
  validateEmail,
} from "../../Lib/Methods";

import "./styles.scss";
import MiniLoader from "../../Components/MiniLoader";
import { productCategories } from "../../Lib/Data";
import dayjs from "dayjs";
import { useForm } from "@mantine/form";

const Orders: React.FC = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<PaginationProps>({
    page: 1,
    limit: 20,
  });

  const { data, isLoading, reloadData } = usePerformRequest<
    PaginatedResponse<OrderProps[]>
  >({
    method: "POST",
    url: Endpoints.GetOrders,
    body: pagination,
  });

  useEffect(() => {
    reloadData();
  }, [pagination]);

  const isUserAuth = context && context.profile;

  const orderForm = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) => (validateEmail(value) ? null : "Enter a valid email"),
    },
  });
  const [noAuthOrders, setNoAuthOrders] = useState<OrderProps[]>([]);
  const FindOrders = async () => {
    const { hasErrors: isFormError } = orderForm.validate();
    const { email } = orderForm.values;
    if (isFormError) {
      DefaultErrorNotification("Please enter a valid email to track order");
    } else {
      const r = await PerformRequest<PaginatedResponse<OrderProps[]>>({
        method: "POST",
        route: Endpoints.GetOrdersNoAuth,
        data: { email },
      });
      if (r && r.status === 404) {
        DefaultErrorNotification("No orders found for that account!");
      }
      if (r && r.status === 401) {
        DefaultErrorNotification("Please login with your email and password!");
      }
      if (r && r.status === 200) {
        setNoAuthOrders(r.data.data);
      }
    }
  };
  return (
    <Container maxWidth="md" className="orders-container">
      {context ? (
        <>
          <Text fw={700} fz="xl" tt="capitalize">
            My Orders
          </Text>
          {!isUserAuth && (
            <div className="flex-row align-center">
              <TextInput
                name="email"
                {...orderForm.getInputProps("email")}
                placeholder="Enter email address"
                mr={20}
                w={200}
              />
              <Button onClick={FindOrders}>Find Orders</Button>
            </div>
          )}

          {isLoading ? (
            <MiniLoader />
          ) : (
            <>
              {data?.count === 0 ? (
                <Container maxWidth="sm">
                  <Alert title="No Orders Found" mt={30}>
                    <IconExclamationCircle color="var(--mantine-color-blue-6)" />
                    <Text>You have not made any purchases yet!</Text>
                    <Link to="/products/all">
                      <Button mt={13} leftSection={<IconArrowLeft size={15} />}>
                        Products
                      </Button>
                    </Link>
                  </Alert>
                </Container>
              ) : (
                <Container maxWidth="sm">
                  <br />
                  <div className="flex-col orders">
                    {context && context.profile ? (
                      <>
                        {data?.data.map((order) => {
                          return (
                            <Paper withBorder key={order.id} className="order">
                              <div className="flex-row top align-center">
                                <Text fw={600}>Reference:</Text>
                                <Text ml={10}>{order.reference}</Text>
                              </div>
                              <Divider w="100%" />
                              <div className="main flex-row justify-between">
                                <div className="body flex-col justify-between">
                                  <div className="flex-row align-center">
                                    <Text fw={600} mr={10}>
                                      Order Date:
                                    </Text>
                                    <Text fw={600} c="blue">
                                      {dayjs(order.dateCreated).format(
                                        "dd, DD MMMM YYYY"
                                      )}
                                    </Text>
                                  </div>
                                  <Text fw={600} fz="h3" c="green">
                                    ₦
                                    {getFinancialValueFromNumeric(order.amount)}
                                  </Text>
                                  <div className="flex-row align-center">
                                    <Text fw={600} mr={10}>
                                      Delivery Status:
                                    </Text>
                                    <Text
                                      fw={600}
                                      tt="capitalize"
                                      c={
                                        order.delivered === "delivered"
                                          ? "green"
                                          : order.delivered === "transit"
                                          ? "blue"
                                          : "orange"
                                      }
                                    >
                                      {order.delivered}
                                    </Text>
                                  </div>
                                  <Link to={`/order/${order.id}`}>
                                    <Button variant="outline" w={140}>
                                      Order Details
                                    </Button>
                                  </Link>
                                </div>
                                <Badge
                                  radius="xs"
                                  size="lg"
                                  fz="sm"
                                  color={
                                    order.status === "success" ? "green" : "red"
                                  }
                                >
                                  {order.status}
                                </Badge>
                              </div>
                            </Paper>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {noAuthOrders.map((order) => {
                          return (
                            <Paper withBorder key={order.id} className="order">
                              <div className="flex-row top align-center">
                                <Text fw={600}>Reference:</Text>
                                <Text ml={10}>{order.reference}</Text>
                              </div>
                              <Divider w="100%" />
                              <div className="main flex-row justify-between">
                                <div className="body flex-col justify-between">
                                  <div className="flex-row align-center">
                                    <Text fw={600} mr={10}>
                                      Order Date:
                                    </Text>
                                    <Text fw={600} c="blue">
                                      {dayjs(order.dateCreated).format(
                                        "dd, DD MMMM YYYY"
                                      )}
                                    </Text>
                                  </div>
                                  <Text fw={600} fz="h3" c="green">
                                    ₦
                                    {getFinancialValueFromNumeric(order.amount)}
                                  </Text>
                                  <div className="flex-row align-center">
                                    <Text fw={600} mr={10}>
                                      Delivery Status:
                                    </Text>
                                    <Text
                                      fw={600}
                                      tt="capitalize"
                                      c={
                                        order.delivered === "delivered"
                                          ? "green"
                                          : order.delivered === "transit"
                                          ? "blue"
                                          : "orange"
                                      }
                                    >
                                      {order.delivered}
                                    </Text>
                                  </div>
                                  <Link to={`/order/${order.id}`}>
                                    <Button variant="outline" w={140}>
                                      Order Details
                                    </Button>
                                  </Link>
                                </div>
                                <Badge
                                  radius="xs"
                                  size="lg"
                                  fz="sm"
                                  color={
                                    order.status === "success" ? "green" : "red"
                                  }
                                >
                                  {order.status}
                                </Badge>
                              </div>
                            </Paper>
                          );
                        })}
                      </>
                    )}
                  </div>
                </Container>
              )}
            </>
          )}
          <div className="flex-row align-center justify-center width-100">
            <Pagination
              mt={20}
              disabled={isLoading}
              value={pagination.page}
              onChange={(e) => setPagination({ ...pagination, page: e })}
              total={
                data && data.totalRecords
                  ? data.totalRecords < 21
                    ? 1
                    : Math.ceil(data.totalRecords / 20)
                  : 1 ?? 10
              }
            />
          </div>
          <br />
        </>
      ) : (
        <MiniLoader />
      )}
    </Container>
  );
};

export default Orders;
