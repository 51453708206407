import { IconBrandWhatsapp } from "@tabler/icons-react";

import "./styles.scss";
export default function FloatingActionButton() {
  return (
    <div className="floating-action-container">
      <span
        title="Contact Us"
        className="button"
        onClick={() => {
          window.open("https://wa.me/2348033095732");
        }}
      >
        <IconBrandWhatsapp size={25} color="green" />
      </span>
    </div>
  );
}
