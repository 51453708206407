import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";

import { AddToCartProps, AppContext } from "./Context/AppContext";
import HomePage from "./Pages/HomePage";
import Navbar from "./Components/Navbar";
import Login from "./Pages/Login";
import Error404 from "./Pages/404/Index";
import Register from "./Pages/Register";
import Products from "./Pages/Products";
import SingleProduct from "./Pages/SingleProduct";
import { PerformRequest, usePerformRequest } from "./Lib/usePerformRequest";
import { NonPaginatedResponse } from "./Lib/Responses";
import { CartProduct, Client } from "./Lib/Types";
import { Endpoints } from "./Lib/Endpoints";

import "./index.scss";
import SavedItems from "./Pages/SavedItems";
import Cart from "./Pages/Cart";
import Orders from "./Pages/Orders";
import SingleOrder from "./Pages/SingleOrder";
import SearchResults from "./Pages/SearchResults";
import Account from "./Pages/Account";
import { Box, Fab } from "@mui/material";
import { IconBrandWhatsapp } from "@tabler/icons-react";
import FloatingActionButton from "./Components/FloatingActionButton";
import { generateRandomString } from "./Lib/Methods";
function App() {
  useEffect(() => {
    const randomString = generateRandomString(32);

    if (!localStorage.getItem("clientID")) {
      localStorage.setItem("clientID", randomString);
    }
  }, []);
  const addProductToCart = async ({
    productID,
    size,
    price,
    colour,
  }: AddToCartProps) => {
    const r = await PerformRequest<NonPaginatedResponse<any>>({
      method: "POST",
      data: { productID, size, price, colour },
      route: Endpoints.AddToCart,
    });
    return r;
  };
  const removeProductFromCart = async (itemID: string) => {
    const r = await PerformRequest<NonPaginatedResponse<any>>({
      method: "DELETE",
      data: { itemID: itemID },
      route: Endpoints.RemoveOneFromCart,
    });
    return r;
  };
  const addProductToCartNoAuth = async ({
    productID,
    size,
    price,
    colour,
  }: AddToCartProps) => {
    const randomString = generateRandomString(32);
    const clientID = localStorage.getItem("clientID") ?? randomString;
    if (!localStorage.getItem("clientID")) {
      localStorage.setItem("clientID", randomString);
    }
    const r = await PerformRequest<NonPaginatedResponse<any>>({
      method: "POST",
      data: { productID, size, price, colour, clientID },
      route: Endpoints.AddToCartNoAuth,
    });
    return r;
  };
  const removeProductFromCartNoAuth = async (itemID: string) => {
    const r = await PerformRequest<NonPaginatedResponse<any>>({
      method: "DELETE",
      data: { itemID: itemID },
      route: `${Endpoints.RemoveOneFromCartNoAuth}/${localStorage.getItem(
        "clientID"
      )}`,
    });
    return r;
  };
  const addProductToSavedItems = async (productID: string) => {
    const r = await PerformRequest<NonPaginatedResponse<any>>({
      method: "POST",
      data: { id: productID },
      route: Endpoints.AddToSavedItems,
    });
    return r;
  };
  const removeProductFromSavedItems = async (productID: string) => {
    const r = await PerformRequest<NonPaginatedResponse<any>>({
      method: "DELETE",
      data: { id: productID },
      route: Endpoints.RemoveFromSavedItems,
    });
    return r;
  };

  const {
    data: profile,
    isLoading: isLoadingProfile,
    reloadData: reloadProfile,
  } = usePerformRequest<NonPaginatedResponse<Client>>({
    method: "GET",
    url: Endpoints.GetProfile,
  });
  const {
    data: savedItems,
    isLoading: isLoadingSavedItems,
    reloadData: reloadSavedItems,
  } = usePerformRequest<NonPaginatedResponse<string[]>>({
    method: "GET",
    url: Endpoints.GetSavedItems,
  });
  const {
    data: cart,
    reloadData: reloadCart,
    isLoading: isLoadingCart,
  } = usePerformRequest<NonPaginatedResponse<CartProduct[]>>({
    method: "GET",
    url: Endpoints.GetCart,
  });
  const {
    data: cartNoAuth,
    reloadData: reloadCartNoAuth,
    isLoading: isLoadingCartNoAuth,
  } = usePerformRequest<NonPaginatedResponse<CartProduct[]>>({
    method: "POST",
    body: { clientID: localStorage.getItem("clientID") },
    url: Endpoints.GetCartNoAuth,
  });
  return (
    <MantineProvider>
      <Notifications position="bottom-right" />
      <AppContext.Provider
        value={{
          profile: profile?.data,
          isLoadingProfile,
          reloadProfile,
          addProductToSavedItems,
          removeProductFromSavedItems,
          savedItems: savedItems?.data ?? [],
          reloadSavedItems,
          isLoadingSavedItems,
          cart:
            cart && cart.data && profile && profile.data && profile.data.id
              ? cart.data
              : cartNoAuth?.data ?? [],
          addProductToCart,
          addProductToCartNoAuth,
          removeProductFromCart,
          removeProductFromCartNoAuth,
          reloadCart,
          reloadCartNoAuth,
          isLoadingCart,
          isLoadingCartNoAuth,
        }}
      >
        <Router>
          <Navbar />
          <Routes>
            <Route path="*" index element={<Error404 />} />

            <Route index path="/" element={<HomePage />} />
            <Route index path="/login" element={<Login />} />
            <Route index path="/register" element={<Register />} />
            <Route index path="/products/:category" element={<Products />} />
            <Route index path="/search" element={<SearchResults />} />
            <Route index path="/saved" element={<SavedItems />} />
            <Route index path="/cart" element={<Cart />} />
            <Route index path="/orders" element={<Orders />} />
            <Route index path="/order/:orderID" element={<SingleOrder />} />
            <Route
              index
              path="/product/:productID"
              element={<SingleProduct />}
            />
            <Route index path="/profile" element={<Account />} />
          </Routes>
          <FloatingActionButton />
        </Router>
      </AppContext.Provider>
    </MantineProvider>
  );
}

export default App;
