import { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Container, Grid } from "@mui/material";

import {
  Select,
  Group,
  TextInput,
  Text,
  Button,
  NumberInput,
  Textarea,
  Loader,
} from "@mantine/core";

import {
  IconInfoCircle,
  IconAt,
  IconClock,
  IconUserShield,
} from "@tabler/icons-react";

import Logo from "../../Assets/IMG/Logo.svg";
import "./styles.scss";
import States from "../../Lib/States.json";

import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getFullDate,
  validateEmail,
} from "../../Lib/Methods";
import { AppContext } from "../../Context/AppContext";

import { useForm } from "@mantine/form";
import { PerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";

export default function Account() {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const gridStatsProps = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
  };
  const profileForm = useForm({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",

      state: "",
      address: "",
    },
    validate: {
      firstname: (value) =>
        value.length === 0 ? "Please enter a valid name" : null,
      lastname: (value) =>
        value.length === 0 ? "Please enter a valid name" : null,
      email: (value) =>
        !validateEmail(value) ? "Please enter a valid email address" : null,
      phone: (value) =>
        value.length < 10 ? "Please enter a valid email phone" : null,

      state: (value) =>
        !value || value.length === 0 ? "Please select your state" : null,
      address: (value) =>
        value.length === 0 ? "Please select your lga" : null,
    },
  });

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (context && context.profile && !context.isLoadingProfile) {
      const { firstname, lastname, email, phone, location } = context.profile;
      profileForm.setValues({
        firstname: firstname ?? "",
        lastname: lastname ?? "",
        email: email ?? "",
        phone: phone ?? "",

        state: location?.state ?? "",
        address: location?.address ?? "",
      });
    }
  }, [context]);
  const states = States.map((state, index) => {
    return { value: state.alias, label: state.state };
  });
  const [lgaOptions, setLgaOptions] = useState<any[]>([]);
  const [isLgaDisabled, setLgaDisabled] = useState<boolean>(false);

  const UpdateProfile = async () => {
    const { hasErrors: isError } = profileForm.validate();
    if (!isError) {
      setLoading(true);
      const { phone, address, state, firstname, lastname, email } =
        profileForm.values;
      const values = {
        phone: phone.toString(),

        firstname: firstname.trim(),
        lastname: lastname.trim(),
        email: email.trim(),
        location: {
          state: state.trim(),
          address: address.trim(),
        },
      };
      const r = await PerformRequest({
        method: "POST",
        data: values,

        route: Endpoints.UpdateProfile,
      }).catch(() => {
        setLoading(false);
        DefaultErrorNotification("Could not update profile! Please try again");
      });
      setLoading(false);
      if (r && r.data && r.status === 200) {
        DefaultSuccessNotification("Updated Profile!");
        if (context) {
          context.reloadProfile();
        }
      }
    } else {
    }
  };
  return (
    <Container maxWidth="lg" className="account-container">
      {context && context.isLoadingProfile ? (
        <Loader />
      ) : (
        <>
          {context && context?.profile && context.profile.id && (
            <>
              <div className="flex-row justify-between width-100">
                <div className="flex-col justify-between width-100">
                  <Text fw={500} size="20px" mb={5}>
                    My Profile
                  </Text>
                  <div className="flex-row align-center">
                    <IconClock color="grey" size={20} />
                    <Text c="dimmed">&nbsp; Created On: &nbsp;</Text>
                    <Text fw={500}>
                      {getFullDate(context.profile.dateCreated)}
                    </Text>
                  </div>
                </div>
              </div>
              <br />
              <Grid container spacing={2}>
                <Grid item {...gridStatsProps}>
                  <TextInput
                    placeholder="First Name"
                    label="First Name"
                    {...profileForm.getInputProps("firstname")}
                  />
                </Grid>
                <Grid item {...gridStatsProps}>
                  <TextInput
                    placeholder="Last Name"
                    label="Last Name"
                    {...profileForm.getInputProps("lastname")}
                  />
                </Grid>
                <Grid item {...gridStatsProps}>
                  <TextInput
                    placeholder="Email Address"
                    label="Email Address"
                    {...profileForm.getInputProps("email")}
                  />
                </Grid>
                <Grid item {...gridStatsProps}>
                  <NumberInput
                    placeholder="Phone"
                    label="Phone"
                    hideControls
                    prefix="+234 "
                    min={0}
                    {...profileForm.getInputProps("phone")}
                  />
                </Grid>
              </Grid>
              <br />
              <Select
                searchable
                label="Select State"
                placeholder="Select your location"
                w="100%"
                data={states}
                {...profileForm.getInputProps("state")}
              />

              <br />

              <Textarea
                label="Address"
                placeholder="Enter your address"
                {...profileForm.getInputProps("address")}
              />
              <br />
              <Button fullWidth onClick={UpdateProfile} loading={isLoading}>
                Save Profile
              </Button>
              <br />
            </>
          )}
        </>
      )}
    </Container>
  );
}
