import { Link } from "react-router-dom";
import { Button, Text } from "@mantine/core";

import Image404 from "../../Assets/IMG/404.jpeg";

import "./styles.scss";
export default function Error404() {
  return (
    <div className="container-404 flex-col">
      <div className="section flex-col align-center justify-between">
        <img src={Image404} alt="error-404-image" className="image" />
        <Text fw={600} fz="30px" c="blue">
          Page Not Found!
        </Text>
        <Link to="/">
          <Button>Back to Home</Button>
        </Link>
      </div>
    </div>
  );
}
