import { useRef, useContext, useEffect, useState } from "react";

import { useNavigate, Link } from "react-router-dom";

import { Container, Grid, Rating } from "@mui/material";
import { Button, Text, Paper, Group, Skeleton } from "@mantine/core";
import { IconHeart, IconShoppingCart } from "@tabler/icons-react";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";

import { Brands, ProductCards } from "../../Lib/Data";
import {
  getEllipsisWithString,
  getFinancialValueFromNumeric,
} from "../../Lib/Methods";
import { AppContext } from "../../Context/AppContext";

import FooterSection from "../../Components/Footer";

// @ts-ignore
import VideoA from "../../Assets/IMG/BS/VideoA.mp4";
// @ts-ignore
import VideoB from "../../Assets/IMG/BS/VideoB.mp4";
// @ts-ignore
import VideoC from "../../Assets/IMG/BS/VideoC.mp4";
// @ts-ignore
import VideoD from "../../Assets/IMG/BS/VideoD.mp4";
// @ts-ignore
import VideoE from "../../Assets/IMG/BS/VideoE.mp4";

import "./styles.scss";
import { usePerformRequest } from "../../Lib/usePerformRequest";
import { PaginatedResponse } from "../../Lib/Responses";
import { ProductProps } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import LandingPage from "../LandingPage";
const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const [isLandingPageShowing, setLandingPageShowing] = useState<boolean>(true);
  useEffect(() => {
    if (context) {
      context.reloadProfile();
    }
  }, []);
  const arr = [
    { title: "Shop Various Wigs", link: "Wigs" },
    { title: "Bone Straight", price: 75000, link: "Wigs" },
    {
      title: "Conrows",
      price: 75000,
      useLink: true,
      link: "/product/Kq02FXVwjiKpeiGJatqsVsODZJIGbywa",
    },
    {
      title: "Knotless and Bouncy Braids Wig",
      price: 85000,
      useLink: true,
      link: "/product/D0d3tghqUeeUu5AaaiVVDQX41CigKoVG",
    },
    { title: "Our Studio", link: "Braids" },
  ];
  const autoplay = useRef(Autoplay({ delay: 4000 }));
  const autoplayReady = useRef(Autoplay({ delay: 1000 }));

  const { data: someProducts, isLoading: isLoadingProducts } =
    usePerformRequest<PaginatedResponse<ProductProps[]>>({
      method: "POST",
      body: {
        page: 1,
        limit: 10,
      },
      url: Endpoints.GetAllProducts,
    });
  return (
    <>
      <LandingPage
        isLandingPageShowing={isLandingPageShowing}
        setLandingPageShowing={setLandingPageShowing}
      />
      {!isLandingPageShowing && (
        <Container maxWidth="xl">
          {context && (
            <div className="home-container">
              <Carousel
                withControls={false}
                withIndicators
                className="jumbo-container"
                height={`calc(70vh - 73px)`}
                plugins={[autoplay.current]}
                onMouseEnter={autoplay.current.stop}
                onMouseLeave={autoplay.current.reset}
              >
                {arr.map((e, index) => {
                  const videos = [VideoD, VideoA, VideoE, VideoB, VideoC];
                  return (
                    <Carousel.Slide key={index}>
                      {/* <div className="jumbo-backdrop"></div> */}
                      {[0, 1, 2, 3].includes(index) ? (
                        <video
                          className={`jumbo-video`}
                          autoPlay={true}
                          loop
                          muted
                        >
                          {/* @ts-ignore */}
                          <source src={videos[index]} type="video/mp4" />
                        </video>
                      ) : (
                        <div
                          className={`jumbo-background jumbo-background-${
                            index + 1
                          }`}
                        ></div>
                      )}
                      <div className="jumbo-content flex-col align-center justify-end">
                        <span className="title">{e.title}</span>
                        {e.price && (
                          <span className="flex-row align-center justify-center price">
                            ₦{getFinancialValueFromNumeric(e.price)}
                          </span>
                        )}
                        <div className="flex-row align-center justify-center">
                          <Link to={e.useLink ? e.link : "/products/wigs"}>
                            <Button>Shop Wigs</Button>
                          </Link>
                        </div>
                      </div>
                    </Carousel.Slide>
                  );
                })}
              </Carousel>
              <div className="categories flex-row align-center justify-center">
                <Grid
                  alignItems="center"
                  justifyContent="center"
                  container
                  spacing={5}
                  display="none"
                >
                  {Brands.map((b, index) => {
                    return (
                      <Grid
                        item
                        key={index}
                        xl={3}
                        lg={3}
                        className="category flex-col align-center justify-between"
                      >
                        <img src={b.image} alt="" className="image" />
                        <Text className="name" tt="uppercase">
                          {b.title}
                        </Text>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
              <div className="product-cards">
                <Group
                  my={30}
                  className="flex-row align-center justify-center width-100"
                >
                  <Text fw={800} fz="1.72rem" tt="uppercase" c="#ff00a1">
                    Featured
                  </Text>

                  <Text fw={800} fz="1.72rem" tt="uppercase" c="#000">
                    Products
                  </Text>
                </Group>
                {isLoadingProducts || !someProducts ? (
                  <Skeleton w="100%" h={250} />
                ) : (
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {someProducts.data.map((product, index) => {
                      // const getDiscountedPrice = () => {
                      //   const dPrice =
                      //     product.price - (product.price * product.discount) / 100;
                      //   return dPrice.toLocaleString();
                      // };
                      const getProductPrices = () => {
                        const prices =
                          product.prices.length === 1
                            ? `₦${getFinancialValueFromNumeric(
                                product.prices[0].price
                              )}`
                            : `₦${getFinancialValueFromNumeric(
                                product.prices
                                  .map((p) => p.price)
                                  .sort((a, b) => b - a)
                                  .reverse()[0]
                              )} - 
                          ₦${getFinancialValueFromNumeric(
                            product.prices
                              .map((p) => p.price)
                              .sort((a, b) => b - a)[0]
                          )}`;
                        return `₦${getFinancialValueFromNumeric(
                          product.prices
                            .map((p) => p.price)
                            .sort((a, b) => b - a)[0]
                        )}`;
                        // return prices;
                      };
                      const featuredImage = product.images.find(
                        (f) => f.cloudinaryID === product.featuredImage
                      );
                      return (
                        <Grid
                          item
                          key={index}
                          alignItems="center"
                          justifyContent="center"
                          justifyItems="center"
                          alignContent="center"
                        >
                          <Paper shadow="xs" className="product-card">
                            <img
                              src={featuredImage?.url}
                              alt=""
                              className="image"
                            />
                            <div className="flex-row align-center justify-center brand-row">
                              <Text className="brand" fz="sm">
                                {product.brand}
                              </Text>
                              &nbsp;
                              <Text className="type" fz="sm" tt="capitalize">
                                {product.type}
                              </Text>
                            </div>
                            <div className="body flex-col justify-between">
                              <Text className="name">
                                {getEllipsisWithString(product.name, 80)}
                              </Text>
                              <div className="review-row flex-row align-center">
                                <Rating
                                  size="small"
                                  name="read-only"
                                  value={5}
                                  readOnly
                                />
                                <Text className="review-count">
                                  {/* ({product.reviewCount} Reviews) */}
                                </Text>
                              </div>
                              <div className="price-row flex-row align-center">
                                {/* <Text className="discounted-price">
                            ₦{getDiscountedPrice()}
                          </Text> */}
                                <Text className="actual-price">
                                  {getProductPrices()}
                                </Text>
                              </div>
                              <div className="flex-row buttons align-center justify-between">
                                <Button
                                  variant="subtle"
                                  fullWidth
                                  onClick={() => {
                                    navigate(`/product/${product.id}`);
                                  }}
                                  color="var(--mantine-color-red-6)"
                                  // rightSection={<IconHeart size={20} />}
                                >
                                  View Product
                                </Button>
                              </div>
                            </div>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </div>
              <Group
                my={30}
                className="flex-row align-center justify-center width-100"
              >
                <Text fw={800} fz="1.72rem" tt="uppercase" c="#ff00a1">
                  Ready To Go
                </Text>

                <Text fw={800} fz="1.72rem" tt="uppercase" c="#000">
                  Products
                </Text>
              </Group>
              {isLoadingProducts || !someProducts ? (
                <Skeleton w="100%" h={250} />
              ) : (
                <Carousel
                  withControls={false}
                  withIndicators
                  height={510}
                  slideSize="23.333333%"
                  slideGap="md"
                  styles={{
                    indicators: {
                      height: 30,
                    },
                    indicator: {
                      backgroundColor: "var(--mantine-color-gray-7)",
                    },
                  }}
                  loop={true}
                  className="ready-carousel"
                  plugins={[autoplayReady.current]}
                >
                  {someProducts.data.map((product, index) => {
                    // const getDiscountedPrice = () => {
                    //   const dPrice =
                    //     product.price - (product.price * product.discount) / 100;
                    //   return dPrice.toLocaleString();
                    // };
                    const getProductPrices = () => {
                      const prices =
                        product.prices.length === 1
                          ? `₦${getFinancialValueFromNumeric(
                              product.prices[0].price
                            )}`
                          : `₦${getFinancialValueFromNumeric(
                              product.prices
                                .map((p) => p.price)
                                .sort((a, b) => b - a)
                                .reverse()[0]
                            )} - 
          ₦${getFinancialValueFromNumeric(
            product.prices.map((p) => p.price).sort((a, b) => b - a)[0]
          )}`;
                      return prices;
                    };
                    const featuredImage = product.images.find(
                      (f) => f.cloudinaryID === product.featuredImage
                    );
                    return (
                      <Carousel.Slide key={index}>
                        <Paper shadow="xs" className="product-card">
                          <img
                            src={featuredImage?.url}
                            alt=""
                            className="image"
                          />
                          <div className="flex-row align-center justify-center brand-row">
                            <Text className="brand" fz="sm">
                              {product.brand}
                            </Text>
                            &nbsp;
                            <Text className="type" fz="sm">
                              {product.type}
                            </Text>
                          </div>
                          <div className="body flex-col justify-between">
                            <Text className="name">
                              {getEllipsisWithString(product.name, 80)}
                            </Text>
                            <div className="review-row flex-row align-center">
                              <Rating
                                size="small"
                                name="read-only"
                                value={5}
                                readOnly
                              />
                              {/* <Text className="review-count">
                          ({product.reviewCount} Reviews)
                        </Text> */}
                            </div>
                            <div className="price-row flex-row align-center">
                              {/* <Text className="discounted-price">
                          ₦{getDiscountedPrice()}
                        </Text> */}
                              <Text className="actual-price">
                                {getProductPrices()}
                              </Text>
                            </div>
                            <Button
                              variant="subtle"
                              fullWidth
                              onClick={() => {
                                navigate(`/product/${product.id}`);
                              }}
                              color="var(--mantine-color-red-6)"
                              // rightSection={<IconHeart size={20} />}
                            >
                              View Product
                            </Button>
                          </div>
                        </Paper>
                      </Carousel.Slide>
                    );
                  })}
                </Carousel>
              )}
              <br />
              <FooterSection />
            </div>
          )}
        </Container>
      )}
    </>
  );
};

export default HomePage;
