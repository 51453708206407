import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";

import { Alert, Button, Pagination, Paper, Rating, Text } from "@mantine/core";
import {
  IconArrowLeft,
  IconExclamationCircle,
  IconHeart,
  IconShoppingCart,
} from "@tabler/icons-react";

import { AppContext } from "../../Context/AppContext";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { NonPaginatedResponse, PaginatedResponse } from "../../Lib/Responses";
import { PaginationProps, ProductProps } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getEllipsisWithString,
  getFinancialValueFromNumeric,
} from "../../Lib/Methods";

import "./styles.scss";
import MiniLoader from "../../Components/MiniLoader";
import { productCategories } from "../../Lib/Data";

const SavedItems: React.FC = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const [savedItems, setSavedItems] = useState<ProductProps[]>([]);
  const [currentLoadingProduct, setCurrentLoadingProduct] =
    useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const GetSavedItems = async () => {
    setLoading(true);
    const r = await PerformRequest<NonPaginatedResponse<ProductProps[]>>({
      method: "POST",
      route: Endpoints.GetBulkProducts,
      data: { ids: context?.savedItems },
    });
    setLoading(false);
    if (r && r.data && r.status === 200) {
      setSavedItems(r.data.data);
    }
  };

  useEffect(() => {
    GetSavedItems();
  }, [context]);
  const ModifySaved = async (productID: string) => {
    const isInSaved = context?.savedItems.includes(productID);
    setCurrentLoadingProduct(productID);
    if (isInSaved) {
      const r = await context?.removeProductFromSavedItems(productID);
      if (r?.status === 200) {
        DefaultSuccessNotification("Product removed");
      }
    } else {
      const r = await context?.addProductToSavedItems(productID);
      if (r?.status === 200) {
        DefaultSuccessNotification("Product saved");
      }
    }
    await context?.reloadSavedItems();
    setCurrentLoadingProduct("");
  };
  return (
    <Container maxWidth="md" className="saved-container">
      {context ? (
        <>
          <Text fw={700} fz="xl" tt="capitalize">
            Saved Items
          </Text>

          {context.isLoadingSavedItems ? (
            <MiniLoader />
          ) : (
            <>
              {context.savedItems?.length === 0 ? (
                <Container maxWidth="sm">
                  <Alert title="No results" mt={30}>
                    <IconExclamationCircle color="var(--mantine-color-blue-6)" />
                    <Text>You have not saved any products!</Text>
                    <Link to="/products/all">
                      <Button mt={13} leftSection={<IconArrowLeft size={15} />}>
                        All Products
                      </Button>
                    </Link>
                  </Alert>
                </Container>
              ) : (
                <div className="product-cards">
                  <Grid
                    container
                    spacing={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {savedItems?.map((product, index) => {
                      const getProductPrices = () => {
                        const prices =
                          product.prices.length === 1
                            ? `₦${getFinancialValueFromNumeric(
                                product.prices[0].price
                              )}`
                            : `₦${getFinancialValueFromNumeric(
                                product.prices
                                  .map((p) => p.price)
                                  .sort((a, b) => b - a)
                                  .reverse()[0]
                              )} - 
                ₦${getFinancialValueFromNumeric(
                  product.prices.map((p) => p.price).sort((a, b) => b - a)[0]
                )}`;
                        return prices;
                      };
                      const rating = product.reviews
                        .map((r) => r.rating)
                        .reduce((p, c) => p + c, 0);
                      const featuredImage = product.images.find(
                        (image) => image.cloudinaryID === product.featuredImage
                      )?.url;
                      return (
                        <Grid
                          item
                          key={index}
                          alignItems="center"
                          justifyContent="center"
                          justifyItems="center"
                          alignContent="center"
                        >
                          <Link to={`/product/${product.id}`}>
                            <Paper shadow="xs" className="product-card">
                              <img
                                src={featuredImage}
                                alt=""
                                className="image"
                              />
                              <div className="flex-row align-center justify-center brand-row">
                                <Text className="brand" fz="sm">
                                  {product.brand}
                                </Text>
                                &nbsp;
                                <Text className="type" fz="sm" tt="capitalize">
                                  {product.type}
                                </Text>
                              </div>
                              <div className="body flex-col justify-between">
                                <Text className="name">
                                  {getEllipsisWithString(product.name, 80)}
                                </Text>
                                <div className="review-row flex-row align-center">
                                  <Rating
                                    size="small"
                                    name="read-only"
                                    value={5}
                                    readOnly
                                  />
                                  <Text className="review-count">
                                    {/* ({product.reviewCount} Reviews) */}
                                  </Text>
                                </div>
                                <div className="price-row flex-row align-center">
                                  {/* <Text className="discounted-price">
                          ₦{getDiscountedPrice()}
                        </Text> */}
                                  <Text className="actual-price">
                                    {getProductPrices()}
                                  </Text>
                                </div>
                                <div className="flex-row buttons align-center justify-between">
                                  <Button
                                    variant="subtle"
                                    loading={
                                      currentLoadingProduct === product.id
                                    }
                                    fullWidth
                                    onClick={(e) => {
                                      e.preventDefault();
                                      ModifySaved(product.id);
                                    }}
                                    color="var(--mantine-color-red-6)"
                                    rightSection={<IconHeart size={20} />}
                                  >
                                    {context.savedItems.includes(product.id)
                                      ? "Remove From Saved"
                                      : "Save"}
                                  </Button>
                                </div>
                              </div>
                            </Paper>
                          </Link>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <MiniLoader />
      )}
    </Container>
  );
};

export default SavedItems;
