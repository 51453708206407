import { Text, Container, Group, Anchor } from "@mantine/core";
import Logo from "../../Assets/IMG/Logo.jpg";
import classes from "./styles.module.css";
import "./styles.scss";

const links = [
  { link: "#", label: "Twitter" },
  { link: "#", label: "Youtube" },
  { link: "#", label: "Facebook" },
  {
    link: "https://www.instagram.com/chapzhairr?igsh=bHN0bWlyaG14b25u",
    label: "Instagram",
  },
];

export default function FooterSection() {
  const items = links.map((link) => (
    <a key={link.label} href={link.link} target="_blank">
      <Text c="dimmed" size="sm">
        {link.label}
      </Text>
    </a>
  ));

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <img src={Logo} alt="" className="footer-image" />
        <Group className={classes.links}>{items}</Group>
      </Container>
      <div className="flex-row align-center justify-center">
        <Text fz="lg" className="flex-row align-center" mb={20}>
          <Text c="dimmed">&copy;{new Date().getFullYear()}.</Text>&nbsp;
          <Text>Chapz Hair. All rights reserved</Text>
        </Text>
      </div>
    </div>
  );
}
