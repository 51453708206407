// export const baseURL = "http://localhost:8080";
// export const baseURL = "http://192.168.18.4:8080";
export const baseURL = "https://chapz-api-production.up.railway.app";

export const Endpoints = {
  Login: `${baseURL}/client/login`,
  Register: `${baseURL}/client/register`,

  GetProfile: `${baseURL}/client/profile/get`,
  UpdateProfile: `${baseURL}/client/profile/update`,

  GetCart: `${baseURL}/client/cart/get`,
  AddToCart: `${baseURL}/client/cart/add`,
  RemoveOneFromCart: `${baseURL}/client/cart/remove`,
  RemoveAllFromCart: `${baseURL}/client/cart/delete`,

  GetCartNoAuth: `${baseURL}/client/cart/no-auth/get`,
  AddToCartNoAuth: `${baseURL}/client/cart/no-auth/add`,
  RemoveOneFromCartNoAuth: `${baseURL}/client/cart/no-auth/remove`,
  RemoveAllFromCartNoAuth: `${baseURL}/client/cart/no-auth/delete/${localStorage.getItem(
    "clientID"
  )}`,

  GetSavedItems: `${baseURL}/client/saved/get`,
  AddToSavedItems: `${baseURL}/client/saved/add`,
  RemoveFromSavedItems: `${baseURL}/client/saved/remove`,

  GetAllProducts: `${baseURL}/products/all`,
  GetSingleProduct: `${baseURL}/product/get`,
  SearchProduct: `${baseURL}/products/search`,
  FitlerProducts: `${baseURL}/products/filter`,
  GetBulkProducts: `${baseURL}/product/bulk/get`,

  CreateOrder: `${baseURL}/order/create`,
  CreateOrderNoAuth: `${baseURL}/order/create/no-auth`,
  GetOrders: `${baseURL}/orders/all`,
  GetOrdersNoAuth: `${baseURL}/orders/all/no-auth`,
  GetSingleOrder: `${baseURL}/order/get`,

  InitiateTransaction: `${baseURL}/transaction/initiate`,

  UploadFile: `${baseURL}/file/upload`,
};
