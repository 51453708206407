import { createContext, useContext } from "react";
import { CartProduct, Client } from "../Lib/Types";
import { NonPaginatedResponse } from "../Lib/Responses";
type PromiseResponse = Promise<{
  status: number;
  data: NonPaginatedResponse<any>;
  message: any;
}>;
export interface AddToCartProps {
  productID: string;
  size: string;
  colour?: string;
  price: number;
}
export type AppContextProps = {
  profile: Client | undefined;
  isLoadingProfile: boolean;
  reloadProfile: () => void;
  savedItems: string[];
  reloadSavedItems: () => void;
  isLoadingSavedItems: boolean;
  cart: CartProduct[];
  reloadCart: () => void;
  reloadCartNoAuth: () => void;
  isLoadingCart: boolean;
  isLoadingCartNoAuth: boolean;
  addProductToCart: (props: AddToCartProps) => PromiseResponse;
  removeProductFromCart: (productID: string) => PromiseResponse;
  addProductToCartNoAuth: (props: AddToCartProps) => PromiseResponse;
  removeProductFromCartNoAuth: (productID: string) => PromiseResponse;
  addProductToSavedItems: (productID: string) => PromiseResponse;
  removeProductFromSavedItems: (productID: string) => PromiseResponse;
} | null;

export const AppContext = createContext<AppContextProps>(null);
