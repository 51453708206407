import { useContext, useState } from "react";

import { useNavigate, Link, useLocation } from "react-router-dom";

import { Fab } from "@mui/material";

import {
  HoverCard,
  Group,
  Button,
  Menu,
  UnstyledButton,
  Text,
  SimpleGrid,
  Anchor,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
  useMantineTheme,
  TextInput,
  ActionIcon,
  Indicator,
  Avatar,
  Loader,
  NavLink,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import {
  IconChevronDown,
  IconArrowRight,
  IconSearch,
  IconHeart,
  IconShoppingCart,
  IconUser,
  IconLogout,
  IconClipboard,
  IconChevronUp,
  IconBrandWhatsapp,
} from "@tabler/icons-react";
import Cookies from "js-cookie";
import { AppContext } from "../../Context/AppContext";

import Logo from "../../Assets/IMG/Logo.jpg";
import classes from "./styles.module.css";
import "./styles.scss";

const navData = [
  {
    title: "Wigs",
    description: "Transform your look instantly with our high-quality wigs.",
  },
  {
    title: "Braids",
    description:
      "Embrace the timeless beauty of braids with our exquisite collection",
  },
  // {
  //   title: "Hair Extensions",
  //   description: "Elevate your hair game with our premium hair extensions",
  // },
];

// NAVBAR ALSO CONTAINS FLOATING ACTION BUTTON
export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(AppContext);
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(true);
  const theme = useMantineTheme();

  const links = navData.map((item) => {
    const link = item.title.toLowerCase().replaceAll(" ", "-");
    return (
      <UnstyledButton
        className={classes.subLink}
        key={item.title}
        onClick={() => {
          if (drawerOpened) {
            toggleDrawer();
          }
        }}
      >
        <Group wrap="nowrap" align="flex-start">
          <Link to={`/products/${link}`}>
            <Text size="sm" fw={500} color="black">
              {item.title}
            </Text>
            <Text size="xs" c="dimmed">
              {item.description}
            </Text>
          </Link>
        </Group>
      </UnstyledButton>
    );
  });
  const noShowRoutes = ["login", "register", "forgot-password"];
  const { pathname } = location;
  const path = pathname.replaceAll("/", "").toLowerCase();

  const cartLength = context?.cart
    .map((c) => c.count)
    ?.reduce((prev, current) => prev + current, 0);

  const LogoutUser = () => {
    Cookies.remove("token");
    navigate("/login");
    context?.reloadProfile();
    context?.reloadSavedItems();
    context?.reloadCart();
  };

  const [searchString, setSearchString] = useState<string>("");
  const SearchStore = () => {
    if (searchString.length > 0) {
      navigate(`/search?q=${searchString}`);
    }
  };
  return (
    <>
      {context && (
        <div
          className={noShowRoutes.includes(path) ? "nav-none" : "nav-container"}
        >
          <Box>
            <div className={classes.header}>
              <Group justify="space-between" h="100%">
                <Link to="/" className="logo-container">
                  <img className="logo" src={Logo} />
                </Link>

                <Group h="100%" gap={0} visibleFrom="sm">
                  <HoverCard
                    width={600}
                    position="bottom"
                    radius="md"
                    shadow="md"
                    withinPortal
                  >
                    <HoverCard.Target>
                      <Center inline className="pointer">
                        <Box component="span" mr={5}>
                          <Text className={classes.link}>Shop</Text>
                        </Box>
                        <IconChevronDown
                          style={{ width: rem(16), height: rem(16) }}
                          color={theme.colors.blue[6]}
                        />
                      </Center>
                    </HoverCard.Target>

                    <HoverCard.Dropdown mt={30} style={{ overflow: "hidden" }}>
                      <Group justify="space-between" px="md">
                        <Text fw={500}>Shop</Text>
                        <Link to="/products/all">
                          <Text c="blue" fz="xs">
                            View all
                          </Text>
                        </Link>
                      </Group>

                      <Divider my="sm" />

                      <SimpleGrid cols={2} spacing={0}>
                        {links}
                      </SimpleGrid>

                      <div className={classes.dropdownFooter}>
                        <Group justify="space-between">
                          <div>
                            <Text fw={500} fz="sm">
                              View All Products
                            </Text>
                            <Text size="xs" c="dimmed">
                              Not sure what you want? Explore our entire
                              collection
                            </Text>
                          </div>
                          <Link to="/products/all">
                            <Button
                              variant="default"
                              rightSection={<IconArrowRight size={14} />}
                            >
                              All Products
                            </Button>
                          </Link>
                        </Group>
                      </div>
                    </HoverCard.Dropdown>
                  </HoverCard>
                  <a href="#" className={classes.link}>
                    Featured
                  </a>
                  <a href="#" className={classes.link}>
                    Contact Us
                  </a>
                </Group>

                <Group visibleFrom="sm">
                  <TextInput
                    type="search"
                    size="sm"
                    w={135}
                    placeholder="Search store"
                    onChange={(e) => {
                      setSearchString(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        SearchStore();
                      }
                    }}
                    leftSection={<IconSearch size={17} />}
                  />
                  {context.isLoadingProfile ? (
                    <Loader size={18} />
                  ) : (
                    <>
                      {context.profile ? (
                        <Menu shadow="md" width={200} withArrow>
                          <Menu.Target>
                            <Avatar
                              className="pointer"
                              color="blue"
                              alt={`${context.profile.firstname} ${context.profile.lastname}`}
                            />
                          </Menu.Target>

                          <Menu.Dropdown>
                            <Menu.Label>
                              {context.profile.firstname}&nbsp;
                              {context.profile.lastname}
                            </Menu.Label>

                            <Link to="/profile">
                              <Menu.Item
                                color="blue"
                                leftSection={
                                  <IconUser
                                    style={{ width: rem(14), height: rem(14) }}
                                  />
                                }
                              >
                                My account
                              </Menu.Item>
                            </Link>
                            <Link to="/orders">
                              <Menu.Item
                                color="blue"
                                leftSection={
                                  <IconClipboard
                                    style={{ width: rem(14), height: rem(14) }}
                                  />
                                }
                              >
                                My Orders
                              </Menu.Item>
                            </Link>

                            <Menu.Divider />
                            <Menu.Item
                              color="red"
                              onClick={LogoutUser}
                              leftSection={
                                <IconLogout
                                  style={{ width: rem(14), height: rem(14) }}
                                />
                              }
                            >
                              Logout
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      ) : (
                        <Link to="/login">
                          <Button variant="default">Log in</Button>
                        </Link>
                      )}
                    </>
                  )}
                  <Link to="/saved">
                    <Indicator
                      disabled={context.savedItems.length === 0}
                      position="top-end"
                      label={context.savedItems.length}
                      size={20}
                    >
                      <ActionIcon
                        color="var(--mantine-color-red-6)"
                        variant="subtle"
                        className="nav-action"
                      >
                        <IconHeart size={20} />
                      </ActionIcon>
                    </Indicator>
                  </Link>
                  <Link to="/cart">
                    <Indicator
                      disabled={
                        !cartLength ||
                        cartLength === 0 ||
                        context.cart.length === 0
                      }
                      position="top-end"
                      label={cartLength}
                      size={20}
                    >
                      <ActionIcon
                        variant="subtle"
                        className="nav-action no-radius"
                      >
                        <IconShoppingCart size={20} />
                      </ActionIcon>
                    </Indicator>
                  </Link>
                </Group>
                <div className="flex-row align-center">
                  <TextInput
                    type="search"
                    size="sm"
                    w={135}
                    h={30}
                    placeholder="Search store"
                    className="small-search"
                  />
                  <ActionIcon
                    mt={5}
                    ml={2}
                    h={35}
                    w={35}
                    className="small-search-button"
                  >
                    <IconSearch size={15} />
                  </ActionIcon>
                </div>
                <div className="small-links flex-row align-center justify-between">
                  <Link to="/saved">
                    <Indicator
                      disabled={context.savedItems.length === 0}
                      position="top-end"
                      label={context.savedItems.length}
                      size={20}
                    >
                      <ActionIcon
                        color="var(--mantine-color-red-6)"
                        variant="subtle"
                        className="nav-action"
                      >
                        <IconHeart size={20} />
                      </ActionIcon>
                    </Indicator>
                  </Link>
                  <Link to="/cart">
                    <Indicator
                      disabled={
                        !cartLength ||
                        cartLength === 0 ||
                        context.cart.length === 0
                      }
                      position="top-end"
                      label={cartLength}
                      size={20}
                    >
                      <ActionIcon
                        variant="subtle"
                        className="nav-action no-radius"
                      >
                        <IconShoppingCart size={20} />
                      </ActionIcon>
                    </Indicator>
                  </Link>
                </div>
                <Burger
                  opened={drawerOpened}
                  onClick={toggleDrawer}
                  hiddenFrom="sm"
                  className="toggle-small-nav"
                />
              </Group>
            </div>

            <Drawer
              opened={drawerOpened}
              onClose={closeDrawer}
              size="100%"
              padding="md"
              title="Menu"
              hiddenFrom="sm"
              zIndex={1000000}
            >
              <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
                <Divider my="sm" />

                <a
                  href="#"
                  className={classes.link}
                  onClick={() => {
                    if (drawerOpened) {
                      closeDrawer();
                    }
                  }}
                >
                  Home
                </a>
                <div className="flex-row align-center justify-between width-100">
                  <UnstyledButton
                    className={classes.link}
                    onClick={toggleLinks}
                  >
                    <Center inline>
                      <Box component="span" mr={5}>
                        Shop
                      </Box>
                      {linksOpened ? (
                        <IconChevronUp
                          style={{ width: rem(16), height: rem(16) }}
                          color={theme.colors.blue[6]}
                        />
                      ) : (
                        <IconChevronDown
                          style={{ width: rem(16), height: rem(16) }}
                          color={theme.colors.blue[6]}
                        />
                      )}
                    </Center>
                  </UnstyledButton>
                  <Link
                    to="/products/all"
                    onClick={() => {
                      if (drawerOpened) {
                        closeDrawer();
                      }
                    }}
                  >
                    <Text c="blue" fz="sm" p="md">
                      All Products
                    </Text>
                  </Link>
                </div>
                <Collapse in={linksOpened}>{links}</Collapse>

                <Divider my="sm" />

                {context && context.profile ? (
                  <>
                    <div
                      className="flex-col"
                      style={{
                        padding:
                          "var(--mantine-spacing-xs) var(--mantine-spacing-md)",
                      }}
                    >
                      <Text c="dimmed">
                        {context.profile.firstname}&nbsp;
                        {context.profile.lastname}
                      </Text>

                      <Link to="/profile">
                        <NavLink
                          onClick={() => {
                            if (drawerOpened) {
                              closeDrawer();
                            }
                          }}
                          color="blue"
                          c="blue"
                          variant="subtle"
                          label="My Account"
                          leftSection={
                            <IconClipboard
                              style={{ width: rem(14), height: rem(14) }}
                            />
                          }
                        />
                      </Link>
                      <Link to="/orders">
                        <NavLink
                          onClick={() => {
                            if (drawerOpened) {
                              closeDrawer();
                            }
                          }}
                          color="blue"
                          c="blue"
                          variant="subtle"
                          label="My Orders"
                          leftSection={
                            <IconUser
                              style={{ width: rem(14), height: rem(14) }}
                            />
                          }
                        />
                      </Link>

                      <NavLink
                        color="red"
                        onClick={(e) => {
                          e.preventDefault();
                          LogoutUser();
                          closeDrawer();
                        }}
                        c="red"
                        variant="subtle"
                        label="Logout"
                        leftSection={
                          <IconLogout
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                      />
                    </div>
                  </>
                ) : (
                  <Group justify="center" grow pb="xl" px="md">
                    <Button
                      onClick={() => {
                        navigate("/login");

                        if (drawerOpened) {
                          closeDrawer();
                        }
                      }}
                      variant="default"
                      w="48%"
                    >
                      Log in
                    </Button>

                    <Button
                      onClick={() => {
                        navigate("/register");

                        if (drawerOpened) {
                          closeDrawer();
                        }
                      }}
                      w="48%"
                    >
                      Sign up
                    </Button>
                  </Group>
                )}
              </ScrollArea>
            </Drawer>
          </Box>
        </div>
      )}
    </>
  );
}
