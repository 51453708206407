import React, { useEffect, useRef, useState } from "react";
import { Container } from "@mui/material";
import { Button, Text } from "@mantine/core";
import { Carousel } from "@mantine/carousel";

import Autoplay from "embla-carousel-autoplay";
import { motion } from "framer-motion";

import ReactPlayer from "react-player";

import Typewriter from "typewriter-effect";
// @ts-ignore
import VideoOne from "../../Assets/IMG/initial/VideoOne.mp4";
// @ts-ignore
import JazzVideo from "../../Assets/IMG/initial/JazzVideo3.mp4";
import ImageOne from "../../Assets/IMG/initial/One.jpg";
import ImageTwo from "../../Assets/IMG/initial/Two.jpg";
import ImageThree from "../../Assets/IMG/initial/Three.jpg";
import ImageFour from "../../Assets/IMG/initial/Four.jpg";
import ImageFive from "../../Assets/IMG/initial/Five.jpg";
import ImageSix from "../../Assets/IMG/initial/Six.jpg";
import ImageSeven from "../../Assets/IMG/initial/Seven.jpg";
// @ts-ignore

import "./styles.scss";

interface LandingPageProps {
  isLandingPageShowing: boolean;
  setLandingPageShowing: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function LandingPage({
  setLandingPageShowing,
  isLandingPageShowing,
}: LandingPageProps) {
  const slides = [
    VideoOne,
    ImageOne,
    ImageTwo,
    ImageThree,
    ImageFour,
    ImageFive,
    ImageSix,
    ImageSeven,
  ];

  const autoplay = useRef(Autoplay({ delay: 10000 }));

  const videoRef = useRef(null);
  useEffect(() => {
    if (isLandingPageShowing) {
      // @ts-ignore
      document
        // @ts-ignore
        .getElementById("landing-page-container")
        .addEventListener("click", () => {
          // @ts-ignore
          videoRef.current.play();
        });
    }
  }, []);
  return (
    <motion.div
      initial={false}
      animate={{
        top: isLandingPageShowing ? 0 : "-200vh",
      }}
      className="landing-page-container"
      id="landing-page-container"
    >
      <div className="hidden-audio">
        {isLandingPageShowing && (
          <video
            autoPlay={true}
            loop
            muted={false}
            playsInline
            controls
            ref={videoRef as any}
          >
            {/* @ts-ignore  */}
            <source
              src={isLandingPageShowing ? JazzVideo : undefined}
              type="video/mp4"
            />
          </video>
        )}
      </div>

      <Carousel
        withIndicators
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        loop
        h="100%"
        w="100%"
        slideSize="100%"
        className="carousel"
      >
        {slides.map((slide, index) => {
          return (
            <>
              {index === 0 ? (
                <Carousel.Slide key={index}>
                  <video className={`jumbo-video`} autoPlay={true} loop muted>
                    {/* @ts-ignore */}
                    <source src={slide} type="video/mp4" />
                  </video>
                </Carousel.Slide>
              ) : (
                <Carousel.Slide
                  classNames={{
                    slide: "slide",
                  }}
                  key={index}
                >
                  <div
                    className="image flex-col align-center justify-center"
                    style={{
                      backgroundImage: `url(${slide})`,
                    }}
                  ></div>
                </Carousel.Slide>
              )}
            </>
          );
        })}
      </Carousel>
      <Container>
        <motion.div
          initial={false}
          animate={{
            display: isLandingPageShowing ? "block" : "none",
          }}
          className="bottom"
        >
          <Text fz="h2" c="white" fw={700}>
            <Typewriter
              options={{
                strings: [
                  "Vast collection of Wigs.",
                  "Vast collection of Braids.",
                  "Buy in bulk.",
                  "Straight to your doorstep!",
                ],
                autoStart: true,
                loop: true,
                deleteSpeed: 30,
                delay: 7,
              }}
            />
          </Text>
          <Button
            onClick={() => {
              //@ts-ignore
              videoRef.current.pause();
              setLandingPageShowing(false);
            }}
            mt={15}
            variant="outline"
            color="white"
            size="lg"
          >
            Enter Site
          </Button>
        </motion.div>
      </Container>
    </motion.div>
  );
}
