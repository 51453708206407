import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";

import { Alert, Button, Pagination, Paper, Rating, Text } from "@mantine/core";
import {
  IconArrowLeft,
  IconExclamationCircle,
  IconHeart,
  IconShoppingCart,
} from "@tabler/icons-react";

import { AppContext } from "../../Context/AppContext";
import { usePerformRequest } from "../../Lib/usePerformRequest";
import { PaginatedResponse } from "../../Lib/Responses";
import { PaginationProps, ProductProps } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getEllipsisWithString,
  getFinancialValueFromNumeric,
} from "../../Lib/Methods";

import "./styles.scss";
import MiniLoader from "../../Components/MiniLoader";
import { productCategories } from "../../Lib/Data";

const Products: React.FC = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { category: categorySlug } = useParams();
  if (!productCategories.includes(categorySlug?.toLowerCase() ?? "")) {
    DefaultErrorNotification("Category not found!");
    navigate("/products/all");
  }

  const categoryName = categorySlug?.replaceAll("-", " ").toLowerCase();

  const [pagination, setPagination] = useState<PaginationProps>({
    page: 1,
    limit: 20,
  });

  const getFilters = () => {
    const type = categorySlug?.toLowerCase();
    if (type === "all") {
      return pagination;
    } else {
      const arr = type?.split("") as string[];
      const lastindexOfS = type?.lastIndexOf("s") as number;
      if (arr[lastindexOfS] === "s" && lastindexOfS === arr.length - 1) {
        arr.pop();
      }
      return { ...pagination, type: arr.join("") };
    }
  };
  const { data, isLoading, reloadData } = usePerformRequest<
    PaginatedResponse<ProductProps[]>
  >({
    method: "POST",
    url: Endpoints.FitlerProducts,
    body: getFilters(),
  });

  useEffect(() => {
    reloadData();
  }, [location, pagination]);

  const [loadingProduct, setLoadingProduct] = useState<string>("");
  const ModifySaved = async (productID: string) => {
    if (context && context.profile) {
      const isInSaved = context?.savedItems.includes(productID);
      setLoadingProduct(productID);
      if (isInSaved) {
        const r = await context?.removeProductFromSavedItems(productID);
        if (r?.status === 200) {
          DefaultSuccessNotification("Product removed");
        }
      } else {
        const r = await context?.addProductToSavedItems(productID);
        if (r?.status === 200) {
          DefaultSuccessNotification("Product saved");
        }
      }
      await context?.reloadSavedItems();
      setLoadingProduct("");
    } else {
      navigate("/login");
    }
  };
  return (
    <Container maxWidth="md" className="products-container">
      {context ? (
        <>
          <Text fw={700} fz="xl" tt="capitalize">
            {categoryName}
          </Text>

          {isLoading ? (
            <MiniLoader />
          ) : (
            <>
              {data?.count === 0 ? (
                <Container maxWidth="sm">
                  <Alert title="No results" mt={30}>
                    <IconExclamationCircle color="var(--mantine-color-blue-6)" />
                    <Text>No products found!</Text>
                    <Link to="/products/all">
                      <Button mt={13} leftSection={<IconArrowLeft size={15} />}>
                        All Products
                      </Button>
                    </Link>
                  </Alert>
                </Container>
              ) : (
                <div className="product-cards">
                  <Grid
                    container
                    spacing={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {data?.data.map((product, index) => {
                      const getProductPrices = () => {
                        const prices =
                          product.prices.length === 1
                            ? `₦${getFinancialValueFromNumeric(
                                product.prices[0].price
                              )}`
                            : `₦${getFinancialValueFromNumeric(
                                product.prices
                                  .map((p) => p.price)
                                  .sort((a, b) => b - a)
                                  .reverse()[0]
                              )} - 
                  ₦${getFinancialValueFromNumeric(
                    product.prices.map((p) => p.price).sort((a, b) => b - a)[0]
                  )}`;
                        return prices;
                      };
                      const rating = product.reviews
                        .map((r) => r.rating)
                        .reduce((p, c) => p + c, 0);
                      const featuredImage = product.images.find(
                        (image) => image.cloudinaryID === product.featuredImage
                      )?.url;
                      return (
                        <Grid
                          item
                          key={index}
                          alignItems="center"
                          justifyContent="center"
                          justifyItems="center"
                          alignContent="center"
                        >
                          <Link to={`/product/${product.id}`}>
                            <Paper shadow="xs" className="product-card">
                              <img
                                src={featuredImage}
                                alt=""
                                className="image"
                              />
                              <div className="flex-row align-center justify-center brand-row">
                                <Text className="brand" fz="sm">
                                  {product.brand}
                                </Text>
                                &nbsp;
                                <Text className="type" fz="sm" tt="capitalize">
                                  {product.type}
                                </Text>
                              </div>
                              <div className="body flex-col justify-between">
                                <Text className="name">
                                  {getEllipsisWithString(product.name, 80)}
                                </Text>
                                <div className="review-row flex-row align-center">
                                  <Rating
                                    size="small"
                                    name="read-only"
                                    value={5}
                                    readOnly
                                  />
                                  <Text className="review-count">
                                    {/* ({product.reviewCount} Reviews) */}
                                  </Text>
                                </div>
                                <div className="price-row flex-row align-center">
                                  {/* <Text className="discounted-price">
                            ₦{getDiscountedPrice()}
                          </Text> */}
                                  <Text className="actual-price">
                                    {getProductPrices()}
                                  </Text>
                                </div>
                                <div className="flex-row buttons align-center justify-between">
                                  <Button
                                    variant="subtle"
                                    loading={loadingProduct === product.id}
                                    fullWidth
                                    onClick={(e) => {
                                      e.preventDefault();
                                      ModifySaved(product.id);
                                    }}
                                    color="var(--mantine-color-red-6)"
                                    rightSection={<IconHeart size={20} />}
                                  >
                                    {context.savedItems.includes(product.id)
                                      ? "Remove From Saved"
                                      : "Save"}
                                  </Button>
                                </div>
                              </div>
                            </Paper>
                          </Link>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              )}
            </>
          )}
          <div className="flex-row align-center justify-center width-100">
            <Pagination
              mt={20}
              disabled={isLoading}
              value={pagination.page}
              onChange={(e) => setPagination({ ...pagination, page: e })}
              total={
                data && data.totalRecords
                  ? data.totalRecords < 21
                    ? 1
                    : Math.ceil(data.totalRecords / 20)
                  : 1 ?? 10
              }
            />
          </div>
          <br />
        </>
      ) : (
        <MiniLoader />
      )}
    </Container>
  );
};

export default Products;
