import { useContext, useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";

import { Text, Button, ActionIcon, Skeleton, Alert } from "@mantine/core";
import { Carousel } from "@mantine/carousel";

import {
  IconArrowLeft,
  IconExclamationCircle,
  IconMinus,
  IconMotorbike,
  IconPlus,
} from "@tabler/icons-react";

import { usePerformRequest } from "../../Lib/usePerformRequest";
import { NonPaginatedResponse } from "../../Lib/Responses";
import { ProductProps } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";

import { getFinancialValueFromNumeric } from "../../Lib/Methods";
import { AppContext } from "../../Context/AppContext";

import "./styles.scss";

const SingleProduct: React.FC = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const { productID } = useParams();
  const [currentImage, setCurrentImage] = useState({
    cloudinaryID: "",
    url: "",
  });
  const [currentPriceID, setCurrentPriceID] = useState<string>("");
  const [currentColour, setCurrentColour] = useState<number>(0);

  const {
    isLoading: isLoadingProduct,
    data: product,
    reloadData: reloadProduct,
    status: productStatus,
  } = usePerformRequest<NonPaginatedResponse<ProductProps>>({
    method: "GET",
    url: `${Endpoints.GetSingleProduct}/${productID}`,
  });

  useEffect(() => {
    if (product && product.data) {
      const { featuredImage: featuredImageID, images, prices } = product.data;
      const featuredImage = images.find(
        (i) => i.cloudinaryID === featuredImageID
      );

      setCurrentImage({
        cloudinaryID: featuredImage?.cloudinaryID as string,
        url: featuredImage?.url as string,
      });
      setCurrentPriceID(prices[0].id);
    }
  }, [product]);
  const rating = product?.data?.reviews
    .map((r) => r.rating)
    .reduce((p, c) => p + c, 0);
  const currentPrice = product?.data.prices.find(
    (p) => p.id === currentPriceID
  );

  const productInCart = context?.cart.find(
    (c) =>
      c.size === currentPrice?.size &&
      c.productID === productID &&
      c.price === currentPrice.price &&
      // @ts-ignore
      c.colour === product?.data.colour[currentColour]
  );

  const [isLoadingCart, setLoadingCart] = useState<boolean>(false);
  const AddToCart = async () => {
    if (context && context.profile) {
      setLoadingCart(true);
      await context?.addProductToCart({
        productID: productID as string,
        size: currentPrice?.size as string,
        price: currentPrice?.price as number,
        //@ts-ignore
        colour: product?.data?.colour[currentColour] as string,
      });
      await context?.reloadCart();
      setLoadingCart(false);
    } else {
      setLoadingCart(true);
      await context?.addProductToCartNoAuth({
        productID: productID as string,
        size: currentPrice?.size as string,
        price: currentPrice?.price as number,
        //@ts-ignore
        colour: product?.data?.colour[currentColour] as string,
      });
      await context?.reloadCartNoAuth();
      setLoadingCart(false);
    }
  };
  const RemoveFromCart = async () => {
    if (context && context.profile) {
      setLoadingCart(true);
      await context?.removeProductFromCart(productInCart?.id as string);
      await context?.reloadCart();
      setLoadingCart(false);
    } else {
      setLoadingCart(true);
      await context?.removeProductFromCartNoAuth(productInCart?.id as string);
      await context?.reloadCartNoAuth();
      setLoadingCart(false);
    }
  };
  return (
    <Container maxWidth="md">
      {productStatus === 404 ? (
        <Container maxWidth="sm">
          <Alert title="Not found" mt={100}>
            <IconExclamationCircle color="var(--mantine-color-blue-6)" />
            <Text>The requested product does not exist!</Text>
            <Link to="/products/all">
              <Button mt={13} leftSection={<IconArrowLeft size={15} />}>
                All Products
              </Button>
            </Link>
          </Alert>
        </Container>
      ) : (
        <>
          <div className="flex-row single-product-container width-100 justify-between">
            <div className="flex-col carousel-container">
              {isLoadingProduct ? (
                <Skeleton height={330} circle mb="xl" />
              ) : (
                <div
                  style={{ backgroundImage: `url(${currentImage.url})` }}
                  className="current-image"
                />
              )}
              <Carousel
                withControls={true}
                controlsOffset={30}
                slideSize="10%"
                slideGap={5}
                withIndicators={false}
                height="fit-content"
                style={{
                  width: "100%",
                }}
              >
                {product?.data.images.map((image) => {
                  return (
                    <Carousel.Slide key={image.cloudinaryID}>
                      <div
                        className={`carousel-item ${
                          currentImage.cloudinaryID === image.cloudinaryID
                            ? "carousel-item-active"
                            : ""
                        }`}
                        style={{
                          backgroundImage: `url(${image.url})`,
                        }}
                        onClick={() => {
                          setCurrentImage({
                            cloudinaryID: image.cloudinaryID,
                            url: image.url,
                          });
                        }}
                      ></div>
                    </Carousel.Slide>
                  );
                })}
              </Carousel>
            </div>
            {isLoadingProduct ? (
              <Skeleton
                w="-webkit-fill-available"
                height={330}
                ml={30}
                radius="xs"
              />
            ) : (
              <div className="flex-col body">
                <Text fw={500} fz={20} mb={10}>
                  {product?.data?.name}
                </Text>
                <Text c="dimmed" mb={10}>
                  {product?.data?.reviews.length} reviews
                </Text>
                <Grid container spacing={3}>
                  {product?.data?.prices.map((price) => {
                    return (
                      <Grid item key={price.id}>
                        <Button
                          variant={
                            currentPriceID === price.id ? "filled" : "light"
                          }
                          size="md"
                          onClick={() => {
                            setCurrentPriceID(price.id);
                          }}
                          fz="sm"
                        >
                          {price.size}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>

                <div className="flex-row align-center">
                  {product?.data.discount && product.data.discount > 0 ? (
                    <div className="flex-col">
                      <Text fw={600} c="green" mt={-15} pt={10}>
                        {product?.data?.discount}% off!
                      </Text>

                      <Text td="line-through" mr={5} fz="sm">
                        ₦
                        {getFinancialValueFromNumeric(
                          (currentPrice?.price as number) -
                            ((currentPrice?.price as number) *
                              product.data.discount) /
                              100
                        )}
                      </Text>
                    </div>
                  ) : (
                    <></>
                  )}
                  <Text fw={600} fz={27} c="#ff00a1" my={20}>
                    ₦{getFinancialValueFromNumeric(currentPrice?.price)}
                  </Text>
                </div>

                <Text c="dimmed">Choose a colour</Text>
                <Grid container spacing={3}>
                  {product?.data?.colour?.map((colour, index) => {
                    return (
                      <Grid item key={index}>
                        <Button
                          color={colour}
                          variant={
                            currentColour === index ? "outline" : "default"
                          }
                          c={
                            colour.toLowerCase() === "white" ? "black" : colour
                          }
                          size="md"
                          onClick={() => {
                            setCurrentColour(index);
                          }}
                          fz="sm"
                        >
                          {colour}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
                <br />
                <div className="flex-row align-center">
                  <IconMotorbike size={18} color="#ff00a1" />{" "}
                  <Text ml={5} c="gray" fz="sm">
                    Fast shipping guaranteed
                  </Text>
                </div>
                <br />
                {productInCart && productInCart.count > 0 ? (
                  <div className="flex-row align-center justify-between cart-row">
                    <ActionIcon
                      onClick={RemoveFromCart}
                      loading={isLoadingCart}
                      size="lg"
                    >
                      <IconMinus size={14} color="white" />
                    </ActionIcon>
                    <Text>{productInCart.count}</Text>
                    <ActionIcon
                      loading={isLoadingCart}
                      size="lg"
                      onClick={AddToCart}
                    >
                      <IconPlus size={14} color="white" />
                    </ActionIcon>
                  </div>
                ) : (
                  <Button onClick={AddToCart} loading={isLoadingCart} w="120px">
                    Add To Cart
                  </Button>
                )}
              </div>
            )}
          </div>
          <Text fw={700} c="#ff00a1" my={20}>
            Product Description
          </Text>
          {isLoadingProduct ? (
            <>
              <Skeleton height={16} mb={10} radius="xs" />

              <Skeleton height={16} mb={10} radius="xs" />

              <Skeleton height={16} mb={10} radius="xs" />

              <Skeleton height={16} mb={10} radius="xs" />

              <Skeleton height={16} mb={10} radius="xs" />

              <Skeleton height={16} mb={10} radius="xs" />

              <Skeleton height={16} mb={10} radius="xs" />
            </>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: product?.data?.description as string,
              }}
            ></div>
          )}
        </>
      )}
    </Container>
  );
};

export default SingleProduct;
