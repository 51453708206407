import { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";

import {
  ActionIcon,
  Alert,
  Button,
  Card,
  Group,
  Skeleton,
  Text,
  Tabs,
  Textarea,
  Select,
  TextInput,
} from "@mantine/core";
import {
  IconArrowLeft,
  IconExclamationCircle,
  IconExternalLink,
  IconMinus,
  IconPencil,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";

import { usePaystackPayment } from "react-paystack";
import { InitializePayment } from "react-paystack/dist/types";

import { AppContext, AppContextProps } from "../../Context/AppContext";

import {
  CartProduct,
  CartProductProps,
  OrderProps,
  ProductProps,
} from "../../Lib/Types";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import {
  InitiateTransactionResponse,
  NonPaginatedResponse,
} from "../../Lib/Responses";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getEllipsisWithString,
  getFinancialValueFromNumeric,
  validateEmail,
} from "../../Lib/Methods";
import States from "../../Lib/States.json";

import "./styles.scss";
import { useForm } from "@mantine/form";
import { countries, pricing } from "../../Lib/Data";

export default function Cart() {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const states = States.map((state) => {
    return {
      value: state.state,
      label: state.state,
    };
  });

  const orderForm = useForm({
    initialValues: {
      state: "",
      address: "",
      country: "",
      email: "",
      name: "",
    },
    validate: {
      name: (value) => (value.length > 0 ? null : "Please enter your name"),
      email: (value) => (validateEmail(value) ? null : "Enter a valid email"),
      country: (value) =>
        !value || value.length === 0 ? "Select your country!" : null,
      state: (value) =>
        !value || value.length === 0 ? "Choose your city/state!" : null,
      address: (value) =>
        !value || value.length === 0 ? "Enter address!" : null,
    },
  });

  useEffect(() => {
    if (context && context.profile) {
      const { firstname, lastname, email } = context.profile;
      orderForm.setFieldValue("email", email);
      orderForm.setFieldValue("name", `${firstname} ${lastname}`);
    }
  }, [context]);
  const getCartTotal = () => {
    if (context && context.cart) {
      return context.cart
        .map((cartItem) => {
          return cartItem.price * cartItem.count;
        })
        .reduce((prev, curr) => prev + curr, 0);
    }
  };

  const [isTransactionLoading, setTransactionLoading] =
    useState<boolean>(false);
  const InitiateCheckout = async (initializePayment: InitializePayment) => {
    const { hasErrors } = orderForm.validate();
    const isAuth = context && context.profile;

    if (hasErrors) {
      DefaultErrorNotification("Enter order address");
    } else {
      setTransactionLoading(true);
      const r = await PerformRequest<
        NonPaginatedResponse<InitiateTransactionResponse>
      >({
        method: "POST",
        data: {
          amount: paystackConfig.amount.toString(),
          clientID: localStorage.getItem("clientID") ?? "",
          email: orderForm.values.email,
        },
        route: Endpoints.InitiateTransaction,
      });
      setTransactionLoading(false);
      if (r && r.status === 200) {
        const HandleSuccess = (e: any) => {
          console.log("Bintu");
          // Create Order
          const CreateOrder = async () => {
            const { address, state, email, name, country } = orderForm.values;

            if (context) {
              const data = {
                email,
                amount: ((getCartTotal() as number) * 100).toString(),
                reference: r.data.data.reference,
                status: "success",
                products: context?.cart.map((item) => {
                  return {
                    id: item.productID,
                    name: item.name,
                    size: item.size,
                    count: item.count,
                    colour: item.colour,
                    unitPrice: item.price,
                  };
                }),
                name,
                location: { address, state, country },
              };

              const r2 = await PerformRequest<NonPaginatedResponse<OrderProps>>(
                {
                  method: "POST",
                  route:
                    context && context.profile
                      ? Endpoints.CreateOrder
                      : Endpoints.CreateOrderNoAuth,
                  data,
                }
              );
              if (r2 && r2.status === 201) {
                window.location.reload();
                DefaultSuccessNotification("Order completed!");
                context?.reloadCart();
                context?.reloadCartNoAuth();
                localStorage.removeItem("clientID");
                window.open(`/order/${r2.data.data.id}`);
              }
            }
          };
          CreateOrder();
        };
        const HandleClose = (e: any) => {
          DefaultErrorNotification("Transacation cancelled!");
        };

        initializePayment({
          onSuccess: HandleSuccess,
          onClose: HandleClose,
          config: { ...paystackConfig, reference: r.data.data.reference },
        });
      } else {
        DefaultErrorNotification("Could not initiate transaction!");
        setTransactionLoading(false);
      }
    }
  };
  const getDeliveryCharge = () => {
    const { state, country } = orderForm.values;
    if (country === "Nigeria") {
      const price = pricing.find((p) => p.regions.includes(state));
      return price?.price ?? 0;
    } else {
      const price = pricing.find((p) => p.regions.includes(country));
      return price?.price ?? 0;
    }
  };
  const paystackConfig = {
    email: (context as any).profile?.email ?? orderForm.values.email,
    amount: parseInt(
      ((getCartTotal() as number) * 100 +
        getDeliveryCharge() * 100) as unknown as string
    ), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_LIVE as string,
  };
  const initializePayment = usePaystackPayment(paystackConfig);
  return (
    <>
      {context && context.cart && (
        <Container maxWidth="sm" style={{ marginTop: "90px" }}>
          <div className="flex-row align-center">
            <Text fw={700} fz="xl" tt="capitalize">
              Cart
            </Text>
            <Text c="blue" ml={10}>
              <Link to="/orders">My Orders</Link>
            </Text>
          </div>
          {context.cart.length === 0 && !context.isLoadingCart ? (
            <Container maxWidth="sm">
              <Alert title="Empty cart" mt={100}>
                <IconExclamationCircle color="var(--mantine-color-blue-6)" />
                <Text>Add some products to your cart to see them here!</Text>
                <Link to="/products/all">
                  <Button mt={13} leftSection={<IconArrowLeft size={15} />}>
                    All Products
                  </Button>
                </Link>
              </Alert>
            </Container>
          ) : (
            <div className="flex-col cart-container justify-between width-100">
              <Card h={600} shadow="sm" className=" flex-col cart-top">
                {getCartTotal() ? (
                  <>
                    <div className="flex-row align-center">
                      <Text c="dimmed">Shipping:</Text>
                      <Text c="blue" fw={600} fz={20} ml={5}>
                        ₦{getFinancialValueFromNumeric(getDeliveryCharge())}
                      </Text>
                    </div>
                    <div className="flex-col">
                      <Text c="dimmed">Total Due Today:</Text>
                      <Text c="blue" fw={600} className="total">
                        ₦
                        {getFinancialValueFromNumeric(
                          (getCartTotal() as number) + getDeliveryCharge()
                        )}
                      </Text>
                    </div>
                    <div className="flex-col">
                      <Text c="dimmed" mb={10}>
                        Delivering to:
                      </Text>
                      <div className="flex-col">
                        <TextInput
                          name="name"
                          placeholder="Enter your full name"
                          w="100%"
                          label="Full Name"
                          {...orderForm.getInputProps("name")}
                        />
                        <TextInput
                          name="email"
                          placeholder="Enter email address"
                          w="100%"
                          my={10}
                          label="Email"
                          {...orderForm.getInputProps("email")}
                        />
                        <div className="flex-row width-100 justify-between align-center">
                          <Select
                            autoComplete="off"
                            data={countries.map((country) => {
                              return {
                                label: country,
                                value: country,
                              };
                            })}
                            {...orderForm.getInputProps("country")}
                            placeholder="Select Country"
                            w="48%"
                            searchable
                            label="Country"
                          />
                          {orderForm.values.country === "Nigeria" ? (
                            <Select
                              autoComplete="off"
                              data={states}
                              {...orderForm.getInputProps("state")}
                              label="Select state"
                              w="48%"
                              searchable
                              placeholder="State"
                            />
                          ) : (
                            <TextInput
                              {...orderForm.getInputProps("state")}
                              label="City/State"
                              w="48%"
                              spellCheck={false}
                              placeholder="Enter city or state"
                            />
                          )}
                        </div>
                        <Textarea
                          label="Order Address"
                          placeholder="Enter address for your order to be sent to"
                          {...orderForm.getInputProps("address")}
                          rows={4}
                          my={10}
                        />
                      </div>
                    </div>

                    <Button
                      loading={isTransactionLoading}
                      fullWidth
                      mt={10}
                      onClick={() => {
                        InitiateCheckout(initializePayment);
                      }}
                    >
                      Checkout
                    </Button>
                  </>
                ) : (
                  <Skeleton h="100%" />
                )}
              </Card>
              <div className="cart-down flex-col">
                {context.cart.map((cartItem) => {
                  return (
                    <CartProductCard
                      product={cartItem}
                      isTransactionLoading={isTransactionLoading}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </Container>
      )}
    </>
  );
}

function CartProductCard({ product, isTransactionLoading }: CartProductProps) {
  const context = useContext(AppContext);
  const {
    data: productDetails,
    isLoading: isLoadingProduct,
    status,
  } = usePerformRequest<NonPaginatedResponse<ProductProps>>({
    method: "GET",
    url: `${Endpoints.GetSingleProduct}/${product.productID}`,
  });
  const featuredImage = productDetails?.data.images.find(
    (image) => image.cloudinaryID === productDetails.data.featuredImage
  );
  const priceStat = productDetails?.data.prices.find(
    (p) => p.size === product.size && p.stock >= 1
  );
  const productStat = priceStat
    ? {
        size: priceStat.size,
        price: priceStat.price,
      }
    : undefined;

  const [isModifying, setModifying] = useState<boolean>(false);
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const DeleteProduct = async () => {
    if (context && context.profile) {
      setDeleting(true);
      const r = await PerformRequest<NonPaginatedResponse<any>>({
        method: "DELETE",
        route: Endpoints.RemoveAllFromCart,
        data: {
          itemID: product.id,
        },
      });
      setDeleting(false);
      if (r && r.status === 200) {
        DefaultSuccessNotification("Removed from cart!");
        context?.reloadCart();
      }
    } else {
      setDeleting(true);
      const r = await PerformRequest<NonPaginatedResponse<any>>({
        method: "DELETE",
        route: Endpoints.RemoveAllFromCartNoAuth,
        data: {
          itemID: product.id,
        },
      });
      setDeleting(false);
      if (r && r.status === 200) {
        DefaultSuccessNotification("Removed from cart!");
        context?.reloadCartNoAuth();
      }
    }
  };
  const AddToCart = async () => {
    if (context && context.profile) {
      setModifying(true);
      await context?.addProductToCart({
        productID: product.productID as string,
        size: priceStat?.size as string,
        price: priceStat?.price as number,
        colour: product.colour,
      });
      await context?.reloadCart();
      setModifying(false);
    } else {
      setModifying(true);
      await context?.addProductToCartNoAuth({
        productID: product.productID as string,
        size: priceStat?.size as string,
        price: priceStat?.price as number,
        colour: product.colour,
      });
      await context?.reloadCartNoAuth();
      setModifying(false);
    }
  };
  const RemoveFromCart = async () => {
    if (context && context.profile) {
      setModifying(true);
      await context?.removeProductFromCart(product?.id as string);
      await context?.reloadCart();
      setModifying(false);
    } else {
      setModifying(true);
      await context?.removeProductFromCartNoAuth(product?.id as string);
      await context?.reloadCartNoAuth();
      setModifying(false);
    }
  };
  const getMaxCharacters = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 900) {
      return 45;
    } else if (screenWidth > 560 && screenWidth < 900) {
      return 75;
    } else {
      return 30;
    }
  };

  return (
    <Card
      shadow="sm"
      className="cart-item flex-row justify-between align-center"
      key={product._id}
    >
      {isLoadingProduct ? (
        <Skeleton h="100%" w="100%" />
      ) : (
        <>
          {status === 404 ? (
            <div className="flex-row width-100 align-center">
              <IconExclamationCircle size={40} color="red" />
              <Text fw={500} fz="lg" ml={10}>
                Product not found!
              </Text>
            </div>
          ) : (
            <>
              <img src={featuredImage?.url} className="image" alt="" />
              <div className="details flex-col justify-between">
                <Link to={`/product/${product.productID}`}>
                  <Text fw={500} className="name">
                    {getEllipsisWithString(
                      productDetails?.data.name ?? "",
                      getMaxCharacters()
                    )}
                  </Text>
                </Link>
                <Text fw={600} className="amount" c="blue">
                  ₦{getFinancialValueFromNumeric(productStat?.price)}
                </Text>
                <div className="flex-row align-center">
                  <Text c="dimmed" fz="sm">
                    Colour:
                  </Text>
                  <Text fz="sm" ml={5}>
                    {product.colour}
                  </Text>
                </div>
                <div className="flex-row align-center">
                  <Text c="dimmed" fz="sm">
                    Size:
                  </Text>
                  <Text fz="sm" ml={5}>
                    {product.size}
                  </Text>
                </div>
                <div className="flex-row align-center justify-between cart-row">
                  <ActionIcon
                    onClick={RemoveFromCart}
                    loading={isModifying}
                    disabled={isDeleting || isTransactionLoading}
                    size="md"
                  >
                    <IconMinus size={14} color="white" />
                  </ActionIcon>
                  <Text>{product.count}</Text>
                  <ActionIcon
                    loading={isModifying}
                    disabled={isDeleting || isTransactionLoading}
                    size="md"
                    onClick={AddToCart}
                  >
                    <IconPlus size={14} color="white" />
                  </ActionIcon>
                </div>
              </div>
              <Group h="80%" align="flex-start">
                <ActionIcon
                  onClick={DeleteProduct}
                  loading={isDeleting}
                  disabled={isModifying || isTransactionLoading}
                  color="red"
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            </>
          )}
        </>
      )}
    </Card>
  );
}
