import BrandImage1 from "../Assets/IMG/Braid1.jpg";
import BrandImage2 from "../Assets/IMG/Braid2.jpg";
import BrandImage3 from "../Assets/IMG/Braid3.jpg";
import BrandImage4 from "../Assets/IMG/Braid4.jpg";
import BrandImage5 from "../Assets/IMG/Braid5.jpg";
import BrandImage6 from "../Assets/IMG/Braid6.jpg";
import BrandImage7 from "../Assets/IMG/Braid7.jpg";
import BrandImage8 from "../Assets/IMG/Braid8.jpg";
export const ProductCards = [
  {
    name: "13×4 Full Frontal HD Lace Wigs Pre-Bleached Knots Glueless Body Wave",
    price: 45000,
    discount: 23,
    type: "Lace",
    brand: "Part Max",
    image:
      "https://static.tinashehair.com/wp-content/uploads/2022/03/Tinashe-hair-glueless-deep-wave-wig-300x300.webp",
    rating: 5,
    reviewCount: 560,
  },
  {
    name: "Part Max 9×6 M-Cap Ready To Go Glueless Lace Wig Body Wave Wigs Pre-Everything Human Hair Wig",
    price: 45000,
    discount: 23,
    type: "Wig",
    brand: "Pre-Everything",
    image:
      "https://static.tinashehair.com/wp-content/uploads/2024/02/Tinashe-hair-glueless-highlight-wig-with-skunk-stripe-1-300x300.webp",
    rating: 5,
    reviewCount: 560,
  },
  {
    name: "Upgraded 6×5 Lace Wig Wear & Go Glueless Straight Wig Pre-Bleached Knots Pre-Cut Lace Wig",
    price: 55650,
    image:
      "https://static.tinashehair.com/wp-content/uploads/2018/12/Tinashe-hair-straight-HD-lace-wig-600x600.webp",
    discount: 13,
    rating: 4.5,
    reviewCount: 1260,
    type: "Lace",
    brand: "Part Max",
  },
  {
    name: "Pre-Bleached Knots Ready To Go Glueless Straight Bob Wig Pre-Cut 6×5 HD Lace Wig",
    image:
      "https://static.tinashehair.com/wp-content/uploads/2019/07/HD-lace-wig-body-wave-300x300.jpg",
    price: 76700,
    discount: 32,
    rating: 5,
    reviewCount: 160,
    type: "Wig",
    brand: "Pre-Everything",
  },
  {
    name: "Upgraded Ready To Go Pre-Cut 6×5 Lace Glueless Wig Body Wave Invisible Knots Natural Hairline",
    price: 45000,
    image:
      "https://static.tinashehair.com/wp-content/uploads/2018/12/Tinashe-hair-parting-max-body-wave-wig-300x300.webp",
    discount: 5,
    rating: 4,
    reviewCount: 344,
    type: "Lace",
    brand: "Part Max",
  },
  {
    name: "Glueless Pre-Cut 6×5 Lace Closure Wig Barrel Curls Shoulder Length Bleached Knots Beginner Friendly",
    price: 90000,
    image:
      "https://static.tinashehair.com/wp-content/uploads/2022/03/Tinashe-hair-wear-go-straight-wig-300x300.webp",
    discount: 10,
    rating: 5,
    reviewCount: 122,
    type: "Wig",
    brand: "Pre-Everything",
  },
  {
    name: "Upgraded Ready To Go 6×5 Lace Wig Pre-Bleached Knots Deep Wave Glueless Wig Put It On & Go",
    price: 121000,
    image:
      "https://static.tinashehair.com/wp-content/uploads/2020/09/straight-lace-closure-bob-wig-300x300.jpg",
    discount: 32,
    rating: 5,
    reviewCount: 192,
    type: "Lace",
    brand: "Part Max",
  },
  {
    name: "Glueless Highlight Brown With Honey Blonde Skunk Stripe Wig Pre-Cut 6×5 HD Lace & Bleached Knots",
    price: 56550,
    image:
      "https://static.tinashehair.com/wp-content/uploads/2022/03/Tinashe-hair-glueless-body-wave-wig-1-300x300.webp",
    discount: 11,
    rating: 5,
    reviewCount: 180,
    type: "Wig",
    brand: "Pre-Everything",
  },
];
export const AfricanCountries = [
  "Algeria",
  "Egypt",
  "Ethiopia",

  "Morocco",
  "Senegal",

  "Tunisia",
  "Zambia",
  "Angola",

  "Botswana",

  "Burundi",
  "Cameroon",

  "Central African Republic",
  "Chad",
  "Comoros",
  "Republic of the Congo",
  "Djibouti",

  "Eritrea",
  "Eswatini",
  "Gabon",

  "Kenya",
  "Lesotho",
  "Libya",
  "Madagascar",
  "Malawi",

  "Mauritius",
  "Mozambique",
  "Namibia ",

  "DR Congo",
  "Rwanda",
  "Sao Tome and Principe",
  "Seychelles",

  "South Africa",
  "South Sudan",
  "Sudan",
  "Tanzania",
  "Uganda",
  "Zimbabwe",
];
export const Brands = [
  { image: BrandImage1, title: "Wear & Go" },
  { image: BrandImage2, title: "Part Max" },
  { image: BrandImage3, title: "HD Lace" },
  { image: BrandImage4, title: "Bleached Knots" },
  { image: BrandImage5, title: "Colourful" },
  { image: BrandImage6, title: "Bob Wig" },
  { image: BrandImage7, title: "New In" },
  { image: BrandImage8, title: "Celestial Braids" },
];

export const productCategories = [
  "wigs",
  "hair-extensions",
  "braids",
  "turbans",
  "all",
];
export const EuropeanCountries = [
  "Andorra",
  "Albania",
  "Austria",
  "Åland Islands",
  "Bosnia and Herzegovina",
  "Belgium",
  "Bulgaria",
  "Belarus",
  "Switzerland",
  "Cyprus",
  "Czech Republic",
  "Germany",
  "Denmark",
  "Estonia",
  "Spain",
  "Finland",
  "Faroe Islands",
  "France",
  "Guernsey",
  "Greece",
  "Croatia",
  "Hungary",
  "Ireland",
  "Isle of Man",
  "Iceland",
  "Italy",
  "Jersey",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Latvia",
  "Monaco",
  "Moldova, Republic of",
  "Macedonia, The Former Yugoslav Republic of",
  "Malta",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Sweden",
  "Slovenia",
  "Svalbard and Jan Mayen",
  "Slovakia",
  "San Marino",
  "Ukraine",
  "Holy See (Vatican City State)",
];

export const NorthAmericanCountries = [
  "Greenland",
  "Mexico",
  "Nicaragua",
  "Honduras",
  "Cuba",
  "Guatemala",
  "Panama",
  "Costa Rica",
  "Dominican Republic",
  "Haiti",
  "Belize",
  "El Salvador",
  "Bahamas",
  "Jamaica",
  "Puerto Rico",
  "Trinidad and Tobago",
  "Guadeloupe",
  "Martinique",
  "Turks and Caicos Islands",
  "Dominica",
  "Saint Lucia",
  "Curaçao",
  "Antigua and Barbuda",
  "Barbados",
  "Saint Vincent and the Grenadines",
  "US Virgin Islands",
  "Grenada",
  "Caribbean Netherlands",
  "Cayman Islands",
  "Saint Kitts and Nevis",
  "Saint Pierre and Miquelon",
  "Aruba",
  "British Virgin Islands",
  "Montserrat",
  "Anguilla",
  "Bermuda",
  "Saint Martin",
  "Sint Maarten",
  "Saint Barthélemy",
  "Clipperton Island",
];
export const SouthAmericanCountries = [
  "Brazil",
  "Colombia",
  "Argentina",
  "Peru",
  "Venezuela",
  "Chile",
  "Ecuador",
  "Bolivia",
  "Paraguay",
  "Uruguay",
  "Guyana",
  "Suriname",
  "French Guiana",
  "Falkland Islands",
];
export const AsianCountries = [
  "Russia",
  "China",
  "India",
  "Kazakhstan",
  "Saudi Arabia",
  "Iran",
  "Mongolia",
  "Indonesia",
  "Pakistan",
  "Turkey",
  "Myanmar",
  "Afghanistan",
  "Yemen",
  "Thailand",
  "Turkmenistan",
  "Uzbekistan",
  "Iraq",
  "Japan",
  "Vietnam",
  "Malaysia",
  "Oman",
  "Philippines",
  "Laos",
  "Kyrgyzstan",
  "Syria",
  "Cambodia",
  "Bangladesh",
  "Nepal",
  "Tajikistan",
  "North Korea",
  "South Korea",
  "Jordan",
  "United Arab Emirates",
  "Azerbaijan",
  "Georgia",
  "Sri Lanka",
  "Egypt",
  "Bhutan",
  "Taiwan",
  "Armenia",
  "Israel",
  "Kuwait",
  "East Timor",
  "Qatar",
  "Lebanon",
  "Palestine",
  "Brunei",
  "Hong Kong",
  "Bahrain",
  "Singapore",
  "Maldives",
  "Macao",
];
export const WestAfricanCountries = [
  "Benin",
  "Burkina Faso",
  "Cape Verde",
  "Gambia",
  "Ghana",
  "Guinea",
  "Guinea-Bissau",
  "Ivory Coast",
  "Liberia",
  "Mali",
  "Mauritania",
  "Niger",
  "Senegal",
  "Sierra Leone",
  "Togo",
  "St. Helena",
];

export const pricing = [
  {
    price: 11500,
    regions: ["Lagos", "Abuja", "Oyo", "Ogun", "Ondo", "Ekiti", "Osun"],
  },
  {
    price: 5000,
    regions: [
      "Rivers",
      "Enugu",
      "Ebonyi",
      "Anambra",
      "Abia",
      "Imo",
      "Cross River",
      "Akwa Ibom",
      "Bayelsa",
      "Delta",
      "Edo",
    ],
  },
  {
    price: 14000,
    regions: [
      "Adamawa",
      "Taraba",
      "Borno",
      "Gombe",
      "Yobe",
      "Bauchi",
      "Kaduna",
      "Kebbi",
      "Zamfara",
      "Kano",
      "Jigawa",
      "Katsina",
      "Sokoto",
      "Benue",
      "Kogi",
      "Plateau",
      "Kwara",
      "Niger",
      "Nasarawa",
    ],
  },
  {
    price: 55000,
    regions: ["United Kingdom"],
  },
  {
    price: 61000,
    regions: WestAfricanCountries,
  },
  {
    price: 67000,
    regions: [
      ...SouthAmericanCountries,
      ...NorthAmericanCountries,
      "United States",
    ],
  },
  {
    price: 77000,
    regions: EuropeanCountries,
  },
  {
    price: 97000,
    regions: AsianCountries,
  },
  {
    price: 100000,
    regions: ["Carribean Island"],
  },
];
export const countries = [
  "Nigeria",
  ...EuropeanCountries,
  ...NorthAmericanCountries,
  ...SouthAmericanCountries,
  ...AsianCountries,
  ...WestAfricanCountries,
  "United Kingdom",
  "United States",
  "Carribean Island",
];
